import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-react-table/styles.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { ClerkProvider, useUser } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import {
  createTheme,
  Loader,
  MantineColorsTuple,
  MantineProvider,
  useComputedColorScheme,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { GISProvider } from "@/contexts/GISContext";
import { ErrorBoundary } from "./ErrorBoundary";
import { HexLoader } from "./HexLoader";

// Pages
import { ProjectDropdownProvider } from "@/providers/ProjectDropdownProvider";
import { Layout } from "./pages/layout/Layout";
import { SignIn } from "./pages/SignIn";

// Portfolio
import PortfolioHome from "./pages/portfolio/PortfolioHome";
import PortfolioList from "./pages/portfolio/PortfolioList";
import PortfolioMap from "./pages/portfolio/PortfolioMap";

// Project Home
import ProjectHome from "./pages/projects/ProjectHome";

// GIS
import BessEnclosureGIS from "./pages/projects/gis/bess-enclosure-gis";
import CombinerBlockGIS from "./pages/projects/gis/combiner-block-gis";
import CombinerGIS from "./pages/projects/gis/combiner-gis";
import PCSGIS from "./pages/projects/gis/pcs-gis";
import TrackerBlockGIS from "./pages/projects/gis/tracker-block-gis";
import TrackerGIS from "./pages/projects/gis/tracker-gis";

// Equipment Analysis
import EquipmentAnalysisBESSBlock from "./pages/projects/equipment_analysis/bess_block/page";
import EquipmentAnalysisBESSPCS from "./pages/projects/equipment_analysis/bess_pcs/page";
import EquipmentAnalysisMetStation from "./pages/projects/equipment_analysis/met_station/page";
import EquipmentAnalysisPVDCCombinerBlock from "./pages/projects/equipment_analysis/pv_dc_combiner/block/page";
import EquipmentAnalysisPVDCCombiner from "./pages/projects/equipment_analysis/pv_dc_combiner/page";
import EquipmentAnalysisPVPCS from "./pages/projects/equipment_analysis/pv_pcs/page";
import EquipmentAnalysisSystem from "./pages/projects/equipment_analysis/system/page";
import EquipmentAnalysisTrackerBlock from "./pages/projects/equipment_analysis/tracker/block/page";
import EquipmentAnalysisTracker from "./pages/projects/equipment_analysis/tracker/page";

// Data Browsing
import ProjectData from "./pages/projects/ProjectData";

// Alerts
import EventPage from "./pages/projects/events/EventPage";
import UptimeTable from "./pages/projects/events/UptimeTable";
import Maximo from "./pages/projects/maximo/Maximo";
import MaximoByAsset from "./pages/projects/maximo/MaximoByAsset";

// KPIs
import ProjectKPIAlerts from "./pages/projects/kpis/ProjectKPIAlerts";
import ProjectKPIBESSBlockAverageSOCPercent from "./pages/projects/kpis/ProjectKPIBESSBlockAverageSOCPercent";
import ProjectKPIBESSBlockCycleCount from "./pages/projects/kpis/ProjectKPIBESSBlockCycleCount";
import ProjectKPIBESSBlockRestingSOCPercent from "./pages/projects/kpis/ProjectKPIBESSBlockRestingSOCPercent";
import ProjectKPIHome from "./pages/projects/kpis/ProjectKPIHome";
import ProjectKPIPCSEnergyProduction from "./pages/projects/kpis/ProjectKPIPCSEnergyProduction";
import ProjectKPIPCSMechanicalAvailability from "./pages/projects/kpis/ProjectKPIPCSMechanicalAvailability";
import ProjectKPIProjectAverageSOCPercent from "./pages/projects/kpis/ProjectKPIProjectAverageSOCPercent";
import ProjectKPIProjectCycleCount from "./pages/projects/kpis/ProjectKPIProjectCycleCount";
import ProjectKPIProjectEnergyProduction from "./pages/projects/kpis/ProjectKPIProjectEnergyProduction";
import ProjectKPIProjectRestingSOC from "./pages/projects/kpis/ProjectKPIProjectRestingSOC";
import ProjectKPIPVDCCombinerFuseHealth from "./pages/projects/kpis/ProjectKPIPVDCCombinerFuseHealth";

// Reports
import ProjectReports from "./pages/projects/ProjectReports";
import CustomReport from "./pages/projects/reports/CustomReport";
import DCAmperageReport from "./pages/projects/reports/DCAmperageReport";

// Project Settings
import ProjectSettings from "./pages/projects/ProjectSettings";

// Project Admin
import ProjectAdmin from "./pages/projects/ProjectAdmin";

// In Development
import ProjectAvailabilityAnalysis from "./pages/projects/ProjectAvailabilityAnalysis";
import ProjectEvents from "./pages/projects/ProjectEvents";
import ProjectLossWaterfall from "./pages/projects/ProjectLossWaterfall";

// Profile
import { AccountSettings } from "./pages/AccountSettings";
import { Api } from "./pages/Api";
import ApplicationSettings from "./pages/ApplicationSettings";

// McCarthy Quality
import Inspections from "./pages/projects/mccarthy_quality/Inspections";
import InspectionsGIS from "./pages/projects/mccarthy_quality/InspectionsGIS";
import Observations from "./pages/projects/mccarthy_quality/Observations";
import ObservationsGIS from "./pages/projects/mccarthy_quality/ObservationsGIS";
import QualityHome from "./pages/projects/mccarthy_quality/QualityHome";

// Development
import RequiresUserType from "./components/admin/RequiresUserType";
import { PageLoader } from "./components/Loading";
import { useTheme } from "./contexts/ThemeContext";
import ERCOTMap from "./pages/development/ercot-map";
import DevelopmentHome from "./pages/development/home";
import Prices from "./pages/development/prices";
import ResourcePage from "./pages/development/resource-page";
import ModuleDegradation from "./pages/projects/reports/ModuleDegradation";
import LoomTesting from "./pages/LoomTesting";

const URL_SIGN_IN = "/sign-in";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

/**
 * AuthLayout component that manages user authentication and access control.
 *
 * This component checks if the user information has been fully loaded and verifies the user's sign-in status.
 * If the user data is still loading, it displays a loading overlay. If the user is not signed in, it redirects
 * them to the sign-in page. If the user is signed in, it renders the main application layout.
 */
const AuthLayout = () => {
  const { isLoaded, isSignedIn } = useUser();

  // If the user is not loaded, return loader
  if (!isLoaded) {
    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        <PageLoader />
      </div>
    );
  }

  // If the user is not signed in, redirect to the sign in page
  if (!isSignedIn) {
    return <Navigate to={URL_SIGN_IN} replace />;
  }

  // If the user is signed in, return the layout
  return <Layout />;
};

/**
 * RequiresTwoFactor component that ensures the user has two-factor authentication enabled.
 *
 * This component checks if the user information is fully loaded and verifies whether the user has
 * two-factor authentication enabled. If the user data is still loading, it displays a loading
 * indicator. If the user does not have two-factor authentication enabled and is not a demo user,
 * it redirects them to the account settings page for two-factor configuration. If the user has
 * two-factor authentication enabled or is a demo user, it renders the child components.
 */
const RequiresTwoFactor = ({ children }: { children: React.ReactNode }) => {
  const { isLoaded, user } = useUser();

  const hasTwoFactorEnabled = user?.twoFactorEnabled;
  const isDemoUser = user?.publicMetadata?.demo;

  // If the user is not loaded, return loader
  if (!isLoaded) {
    return <PageLoader />;
  }

  // If the user does not have two factor enabled and they are not a demo user, redirect to the account settings page for two factor configuration
  if (!hasTwoFactorEnabled && !isDemoUser) {
    return <Navigate to="/account-settings#/security" replace />;
  }

  // If the user has two factor enabled or is a demo user, return the children
  return children;
};

const ClerkProviderWithRoutes = () => {
  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });

  return (
    <ClerkProvider
      // If computedColorScheme is "dark", use the dark theme
      appearance={
        computedColorScheme === "dark" ? { baseTheme: dark } : undefined
      }
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
    >
      <Routes>
        {/* Public routes */}
        <Route path={URL_SIGN_IN} element={<SignIn />} />
        <Route path="/sign-up" element={<Navigate to={URL_SIGN_IN} />} />

        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Navigate to="/portfolio/list" replace />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route
            element={
              <RequiresTwoFactor>
                <Outlet />
              </RequiresTwoFactor>
            }
          >
            <Route
              path="/application-settings"
              element={<ApplicationSettings />}
            />
            <Route path="/api" element={<Api />} />
            <Route path="/loom-testing" element={<LoomTesting />} />

            {/* Portfolio */}
            <Route path="/portfolio">
              <Route path="home" element={<PortfolioHome />} />
              <Route path="list" element={<PortfolioList />} />
              <Route path="map" element={<PortfolioMap />} />
            </Route>

            {/* Project */}
            <Route path="/projects/:projectId">
              <Route index element={<ProjectHome />} />

              {/* GIS */}
              <Route path="gis">
                <Route path="bess-enclosure" element={<BessEnclosureGIS />} />
                <Route path="pv-dc-combiner">
                  <Route index element={<CombinerGIS />} />
                  <Route path=":blockId" element={<CombinerBlockGIS />} />
                </Route>
                <Route path="pv-pcs" element={<PCSGIS />} />
                <Route path="tracker">
                  <Route index element={<TrackerGIS />} />
                  <Route path=":blockId" element={<TrackerBlockGIS />} />
                </Route>
              </Route>

              {/* Equipment Analysis */}
              <Route path="equipment-analysis">
                <Route
                  path="bess-block"
                  element={<EquipmentAnalysisBESSBlock />}
                />
                <Route path="bess-pcs" element={<EquipmentAnalysisBESSPCS />} />
                <Route
                  path="met-station"
                  element={<EquipmentAnalysisMetStation />}
                />
                <Route path="pv-dc-combiner">
                  <Route index element={<EquipmentAnalysisPVDCCombiner />} />
                  <Route path=":combinerId" element={<div>Combiner ID</div>} />
                  <Route path="block">
                    <Route
                      index
                      element={<EquipmentAnalysisPVDCCombinerBlock />}
                    />
                  </Route>
                </Route>
                <Route path="pv-pcs">
                  <Route index element={<EquipmentAnalysisPVPCS />} />
                </Route>
                <Route path="system" element={<EquipmentAnalysisSystem />} />
                <Route path="tracker">
                  <Route index element={<EquipmentAnalysisTracker />} />
                  <Route path=":blockId" element={<div>Block ID</div>} />
                  <Route path="block">
                    <Route index element={<EquipmentAnalysisTrackerBlock />} />
                  </Route>
                </Route>
              </Route>

              {/* Events */}
              <Route path="events">
                <Route index element={<ProjectEvents />} />
                <Route path="event" element={<EventPage />} />
                <Route path="uptime" element={<UptimeTable />} />
              </Route>

              {/* Maximo */}
              <Route path="maximo">
                <Route index element={<Maximo />} />
                <Route path="by-asset" element={<MaximoByAsset />} />
              </Route>

              {/* KPIs */}
              <Route path="kpis">
                <Route index element={<ProjectKPIHome />} />
                <Route path="alerts" element={<ProjectKPIAlerts />} />
                <Route
                  path="bess-block-average-soc-percent"
                  element={<ProjectKPIBESSBlockAverageSOCPercent />}
                />
                <Route
                  path="bess-block-cycle-count"
                  element={<ProjectKPIBESSBlockCycleCount />}
                />
                <Route
                  path="bess-block-resting-soc-percent"
                  element={<ProjectKPIBESSBlockRestingSOCPercent />}
                />
                <Route
                  path="project-average-soc-percent"
                  element={<ProjectKPIProjectAverageSOCPercent />}
                />
                <Route
                  path="project-cycle-count"
                  element={<ProjectKPIProjectCycleCount />}
                />
                <Route
                  path="project-energy-production"
                  element={<ProjectKPIProjectEnergyProduction />}
                />
                <Route
                  path="project-resting-soc-percent"
                  element={<ProjectKPIProjectRestingSOC />}
                />
                <Route
                  path="pv-dc-combiner-fuse-health"
                  element={<ProjectKPIPVDCCombinerFuseHealth />}
                />
                <Route
                  path="pv-pcs-energy-production"
                  element={<ProjectKPIPCSEnergyProduction />}
                />
                <Route
                  path="pv-pcs-mechanical-availability"
                  element={<ProjectKPIPCSMechanicalAvailability />}
                />
              </Route>

              {/* Reports */}
              <Route path="reports" element={<ProjectReports />} />
              <Route
                path="reports/dc-amperage"
                element={<DCAmperageReport />}
              />
              <Route
                path="reports/dc-amperage-v2"
                element={<DCAmperageReport />}
              />
              <Route
                path="reports/module-degradation"
                element={<ModuleDegradation />}
              />
              <Route path="reports/custom" element={<CustomReport />} />

              {/* Data Browsing */}
              <Route path="data-browsing" element={<ProjectData />} />

              {/* McCarthy Quality */}
              <Route path="quality">
                <Route index element={<QualityHome />} />
                <Route path="inspections" element={<Inspections />} />
                <Route path="inspections/gis" element={<InspectionsGIS />} />
                <Route path="observations" element={<Observations />} />
                <Route path="observations/gis" element={<ObservationsGIS />} />
              </Route>

              {/* Project Settings */}
              <Route path="settings" element={<ProjectSettings />} />

              {/* Project Admin */}
              <Route
                path="admin"
                element={
                  <RequiresUserType requiredUserType="admin">
                    <ProjectAdmin />
                  </RequiresUserType>
                }
              />

              {/* In Development */}
              <Route path="loss-waterfall" element={<ProjectLossWaterfall />} />
              <Route
                path="availability-analysis"
                element={<ProjectAvailabilityAnalysis />}
              />
            </Route>

            {/* Development */}
            <Route path="/development">
              <Route index element={<ERCOTMap />} />
              <Route path="resources">
                <Route index element={<DevelopmentHome />} />
                <Route path=":resourceId" element={<ResourcePage />} />
              </Route>
              <Route path="prices" element={<Prices />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </ClerkProvider>
  );
};

// https://hihayk.github.io/scale/#2/7/10/90/0/0/0/0/00AEEF/0/174/239/white
const proximalBlue: MantineColorsTuple = [
  "#E6F7FD",
  "#C5EDFB",
  "#A4E2F9",
  "#83D8F7",
  "#62CDF5",
  "#42C3F3",
  "#21B8F1",
  "#00AEEF",
  "#00A5E3",
  "#009DD7",
];

// https://hihayk.github.io/scale/#2/7/10/90/0/0/0/0/231F20/35/31/32/white
const proximalBlack: MantineColorsTuple = [
  "#E9E9E9",
  "#CDCCCC",
  "#B0AFAF",
  "#949293",
  "#787576",
  "#5C5959",
  "#3F3C3D",
  "#231F20",
  "#211E1E",
  "#201C1D",
];

export default function App() {
  const { primaryColor } = useTheme();

  const theme = createTheme({
    colors: {
      "proximal-blue": proximalBlue,
      "proximal-black": proximalBlack,
    },
    primaryShade: { light: 7, dark: 7 },
    breakpoints: { xl: "92em" },
    primaryColor: primaryColor,
    // https://stackoverflow.com/questions/8118741/css-font-helvetica-neue
    fontFamily: "Helvetica Neue, sans-serif",
    components: {
      Loader: Loader.extend({
        defaultProps: {
          loaders: { ...Loader.defaultLoaders, hex: HexLoader },
          type: "hex",
        },
      }),
    },
  });

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme} defaultColorScheme="auto">
          <ErrorBoundary>
            <Notifications
              autoClose={5000}
              position="bottom-right"
              zIndex={800}
            />

            <ProjectDropdownProvider>
              <GISProvider>
                <ClerkProviderWithRoutes />
              </GISProvider>
            </ProjectDropdownProvider>
          </ErrorBoundary>
        </MantineProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </QueryClientProvider>
    </BrowserRouter>
  );
}
