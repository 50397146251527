import { Link, useParams, useSearchParams } from "react-router-dom";

import { useGetBlockDropdown } from "@/api/ui";
import BlockDropdown from "@/components/BlockDropdown";
import CustomCard from "@/components/CustomCard";
import { AdvancedDatePicker } from "@/components/GIS";
import { useValidateDateRange } from "@/components/datepicker/utils";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetDevices, useGetProject, useGetTimeSeries } from "@/hooks/api";
import { Button, Group, Stack, Title } from "@mantine/core";
import { IconArrowBackUp } from "@tabler/icons-react";
import { useProjectDropdownToggle } from "@/hooks/custom";

const MAX_DAYS = 7;

const ProjectEquipmentAnalysisTrackerBlock = () => {
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useProjectDropdownToggle();

  const deviceId = searchParams.get("deviceId");
  const startURI = searchParams.get("start");
  const endURI = searchParams.get("end");

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { start, end } = useValidateDateRange({
    maxDays: MAX_DAYS,
  });

  let startRequest, endRequest;
  if (project.data) {
    startRequest =
      start && start.tz(project.data.time_zone, true).toISOString();
    endRequest = end && end.tz(project.data.time_zone, true).toISOString();
  }

  const handleBlockDropdownChange = (value: string | null) => {
    if (value) {
      setSearchParams({
        deviceId: value,
        start: startURI || "",
        end: endURI || "",
      });
    }
  };

  const blockDropdown = useGetBlockDropdown({
    pathParams: { projectId: projectId || "-1" },
  });

  const deviceData = useGetDevices({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      device_type_ids: [2, 6, 10],
    },
  });

  const hasPVPCSGroups = deviceData.data?.some((d) => d.device_type_id === 10);

  let device_ids: number[];
  if (hasPVPCSGroups) {
    const PVPCSids = deviceData.data
      ?.filter((d) => d.parent_device_id === Number(deviceId))
      .map((d) => d.device_id);

    const PVPCSTrackerGroupids = deviceData.data
      ?.filter((d) => PVPCSids?.includes(d.parent_device_id ?? -1))
      .map((d) => d.device_id);

    device_ids = PVPCSTrackerGroupids || [];
  } else {
    device_ids = [Number(deviceId) || -1];
  }

  const trackerData = useGetTimeSeries({
    pathParams: {
      projectId: projectId || "-1",
    },
    queryParams: {
      device_ids: device_ids,
      sensor_type_name_shorts: ["tracker_position", "tracker_setpoint"],
      start: startRequest ?? undefined,
      end: endRequest ?? undefined,
    },
    queryOptions: {
      enabled: !!deviceId,
      refetchOnWindowFocus: false,
    },
  });

  return (
    <Stack p="md">
      <Title order={1}>Tracker Equipment Analysis</Title>
      <Group>
        <BlockDropdown
          data={blockDropdown.data}
          value={deviceId}
          onChange={handleBlockDropdownChange}
          buttonPx={2}
        />
        <AdvancedDatePicker
          includeClearButton={false}
          defaultRange="today"
          includeTodayInDateRange
          limits={{
            day: 7,
            week: 1,
            month: 0,
            quarter: 0,
            year: 0,
          }}
          disableQuickActions={true}
          maxDays={MAX_DAYS}
        />
        <Link
          to={`/projects/${projectId}/equipment-analysis/tracker?start=${startURI}&end=${endURI}`}
        >
          <Button variant="light" rightSection={<IconArrowBackUp size={14} />}>
            Back to Project
          </Button>
        </Link>
      </Group>
      <CustomCard title="Tracker Position" style={{ height: "50vh" }}>
        <PlotlyPlot
          data={trackerData.data
            ?.filter((d) => d.sensor_type_name === "tracker_position")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: `${d.tag_name_long}`,
              hoverlabel: {
                namelength: -1,
              },
            }))}
          isLoading={trackerData.isLoading}
          error={trackerData.error}
        />
      </CustomCard>
      <CustomCard title="Tracker Setpoint" style={{ height: "50vh" }}>
        <PlotlyPlot
          data={trackerData.data
            ?.filter((d) => d.sensor_type_name === "tracker_setpoint")
            .map((d) => ({
              x: d.x,
              y: d.y,
              name: `${d.tag_name_long}`,
              hoverlabel: {
                namelength: -1,
              },
            }))}
          isLoading={trackerData.isLoading}
          error={trackerData.error}
        />
      </CustomCard>
    </Stack>
  );
};

export default ProjectEquipmentAnalysisTrackerBlock;
