import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetKPIDevicesTemplate,
  useGetKPIType,
  useGetProject,
} from "@/hooks/api";
import { KPIDevicesTemplate } from "@/hooks/types";
import {
  ActionIcon,
  Box,
  Button,
  Group,
  HoverCard,
  Popover,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconInfoCircle, IconSettings } from "@tabler/icons-react";
import { Data, Layout } from "plotly.js";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Page = ({
  kpiTypeId,
  cardTitle,
}: {
  kpiTypeId: number;
  cardTitle: string;
}) => {
  const { projectId } = useParams();

  const [plotType, setPlotType] = useState("bar");

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { start, end } = useValidateDateRange({});

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  if (project.data) {
    if (start) {
      startQuery = start.tz(project.data.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.data.time_zone, true).toISOString();
    }
  }

  const kpiType = useGetKPIType({
    pathParams: { kpiTypeId },
  });

  // Query KPI data
  const data = useGetKPIDevicesTemplate({
    pathParams: {
      projectId: projectId || "-1",
      kpiTypeNameShort: kpiType.data?.name_short.replace(/_/g, "-") || "",
    },
    queryParams: {
      start: startQuery,
      end: endQuery,
    },
    queryOptions: {
      enabled: !!project.data && !!start && !!end && !!kpiType.data,
      staleTime: Infinity,
    },
  });

  const pageIsLoading = project.isLoading || kpiType.isLoading;

  const parseData = (
    plotType: string,
  ): { data: Data[]; layout: Partial<Layout> } => {
    if (!data.data) return { data: [], layout: {} };

    switch (plotType) {
      case "bar": {
        // TODO: Need to handle the case where we average values
        const y = data.data.data.values.reduce(
          (sum, row) => sum.map((value, index) => value + (row[index] || 0)),
          new Array(data.data.data.values[0].length).fill(0),
        );

        return {
          data: [
            {
              type: "bar",
              x: data.data.data.names,
              y: y,
            },
          ],
          layout: {
            yaxis: {
              title: kpiType.data?.unit,
            },
          },
        };
      }

      case "heatmap": {
        const z = data.data.data.values[0].map((_, colIndex) =>
          data.data.data.values.map((row) => row[colIndex]),
        );

        return {
          data: [
            {
              type: "heatmap",
              z: z,
              x: data.data.data.timestamps,
              y: data.data.data.names,
              colorbar: {
                title: kpiType.data?.unit,
              },
            },
          ],
          layout: {
            yaxis: {
              type: "category",
            },
          },
        };
      }

      case "line": {
        return {
          data: data.data.data.names.map((name, index) => ({
            type: "scatter",
            mode: "lines",
            x: data.data.data.timestamps,
            y: data.data.data.values.map((row) => row[index]),
            name: name,
          })),
          layout: {
            yaxis: {
              title: kpiType.data?.unit,
            },
          },
        };
      }

      default:
        return { data: [], layout: {} };
    }
  };

  const parsedData = parseData(plotType);

  return (
    <Box h="100%" w="100%">
      {pageIsLoading && <PageLoader />}
      <Stack p="md" h="100%" display={pageIsLoading ? "none" : "flex"}>
        <Group gap="xs">
          {kpiType.data && <Title order={1}>{kpiType.data.name_long}</Title>}
          {kpiType.data?.description && (
            <HoverCard shadow="md" styles={{ dropdown: { maxWidth: "33%" } }}>
              <HoverCard.Target>
                <IconInfoCircle size={30} />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">{kpiType.data?.description}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
        </Group>
        <Group justify="space-between">
          <AdvancedDatePicker
            includeClearButton={false}
            defaultRange="past-week"
          />
          <Button
            disabled={data.isLoading}
            onClick={() =>
              data.data &&
              download({
                name: kpiType.data?.name_long || "kpi_data",
                data: data.data,
              })
            }
          >
            Download
          </Button>
        </Group>
        <CustomCard
          title={cardTitle}
          headerChildren={
            <Popover>
              <Popover.Target>
                <ActionIcon variant="default">
                  <IconSettings />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <SegmentedControl
                  size="xs"
                  data={[
                    { label: "Bar", value: "bar" },
                    { label: "Heatmap", value: "heatmap" },
                    { label: "Line", value: "line" },
                  ]}
                  value={plotType}
                  onChange={(value) => setPlotType(value)}
                />
              </Popover.Dropdown>
            </Popover>
          }
          style={{ flex: 1 }}
        >
          <PlotlyPlot
            // Force re-render when plotType changes. For some reason Plotly was reserving extra space for the legend when switching from the line plot to either of the other two.
            key={plotType}
            data={parsedData.data}
            layout={parsedData.layout}
            colorscale={"primary"}
            isLoading={data.isLoading}
          />
        </CustomCard>
      </Stack>
    </Box>
  );
};

const download = ({
  name,
  data,
}: {
  name: string;
  data: KPIDevicesTemplate;
}) => {
  if (!data || !data.data) return;

  // Create CSV content
  const csvContent = [
    ["Timestamp", ...data.data.names].join(","),
    ...data.data.timestamps.map((timestamp, i) =>
      [timestamp, ...data.data.values[i]].join(","),
    ),
  ].join("\n");

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link and trigger the download
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${name}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default Page;
