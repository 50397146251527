import * as icons from "@tabler/icons-react";
import React from "react";

export type Link = {
  label: string;
  to: string | ((arg: string) => string);
  requiresPV?: boolean;
  requiresBESS?: boolean;
  requiresMetStations?: boolean;
  requiresPVDCCombiners?: boolean;
  requiresTrackers?: boolean;
  requiresBESSBlocks?: boolean;
  requiresBESSEnclosures?: boolean;
  requiresBESSPCSs?: boolean;
  underDevelopment?: boolean;
};

export type DropdownLink = {
  icon: React.ElementType;
  label: string;
  to?: string | ((arg: string) => string);
  requiresEventIntegration?: boolean;
  requiresReportIntegration?: boolean;
  requiresMaximoIntegration?: boolean;
  requiresQualityIntegration?: boolean;
  links?: Link[];
  underDevelopment?: boolean;
  userTypeRequired?: string;
};

export const portfolioLinks: DropdownLink[] = [
  {
    to: "/portfolio/home",
    label: "Home",
    icon: icons.IconHome,
    underDevelopment: true,
  },
  {
    to: "/portfolio/list",
    label: "List",
    icon: icons.IconList,
  },
  {
    to: "/portfolio/map",
    label: "Map",
    icon: icons.IconAffiliateFilled,
  },
];

export const projectLinks: DropdownLink[] = [
  {
    to: (projectId: string) => `/projects/${projectId}`,
    label: "Home",
    icon: icons.IconHome,
  },
  // {
  //   link: (projectId: string) => `/projects/${projectId}/single-line`,
  //   label: "Single Line",
  //   icon: icons.IconCircuitCellPlus,
  //   underDevelopment: true,
  // },
  {
    label: "Equipment Analysis",
    icon: icons.IconChartInfographic,
    links: [
      {
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/system`,
        label: "System",
      },
      {
        label: "PV PCS",
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/pv-pcs`,
        requiresPV: true,
      },
      {
        label: "PV DC Combiner",
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/pv-dc-combiner`,
        requiresPVDCCombiners: true,
        requiresPV: true,
      },
      {
        label: "Tracker",
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/tracker`,
        requiresPV: true,
        requiresTrackers: true,
      },
      {
        label: "BESS Block",
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/bess-block`,
        requiresBESS: true,
        requiresBESSBlocks: true,
      },
      {
        label: "BESS PCS",
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/bess-pcs`,
        requiresBESS: true,
        requiresBESSPCSs: true,
      },
      {
        to: (projectId: string) =>
          `/projects/${projectId}/equipment-analysis/met-station`,
        label: "Met Station",
        requiresMetStations: true,
      },
    ],
  },
  {
    label: "Events",
    icon: icons.IconExclamationCircle,
    requiresEventIntegration: true,
    links: [
      {
        to: (projectId: string) => `/projects/${projectId}/events`,
        label: "Events",
      },
      {
        to: (projectId: string) => `/projects/${projectId}/events/uptime`,
        label: "Uptime",
      },
    ],
  },
  {
    to: (projectId: string) => `/projects/${projectId}/kpis`,
    label: "KPIs",
    icon: icons.IconChartLine,
  },
  {
    to: (projectId: string) => `/projects/${projectId}/maximo`,
    label: "Maximo",
    icon: icons.IconTool,
    requiresMaximoIntegration: true,
  },
  {
    to: (projectId: string) => `/projects/${projectId}/reports`,
    label: "Reports",
    icon: icons.IconReport,
    requiresReportIntegration: true,
  },
  {
    to: (projectId: string) => `/projects/${projectId}/data-browsing`,
    label: "Data Browsing",
    icon: icons.IconChartAreaLine,
  },
  {
    label: "Maps",
    icon: icons.IconWorld,
    links: [
      {
        label: "PV PCS",
        to: (projectId: string) => `/projects/${projectId}/gis/pv-pcs`,
        requiresPV: true,
      },
      {
        label: "PV DC Combiner",
        to: (projectId: string) => `/projects/${projectId}/gis/pv-dc-combiner`,
        requiresPVDCCombiners: true,
        requiresPV: true,
      },
      {
        label: "Tracker",
        to: (projectId: string) => `/projects/${projectId}/gis/tracker`,
        requiresPV: true,
        requiresTrackers: true,
      },
      {
        label: "BESS Enclosure",
        to: (projectId: string) => `/projects/${projectId}/gis/bess-enclosure`,
        requiresBESS: true,
        requiresBESSEnclosures: true,
      },
    ],
  },
  {
    to: (projectId: string) => `/projects/${projectId}/loss-waterfall`,
    label: "Loss Waterfall",
    icon: icons.IconBucketDroplet,
    underDevelopment: true,
  },
  {
    to: (projectId: string) => `/projects/${projectId}/quality`,
    label: "Quality",
    icon: icons.IconHexagonLetterQFilled,
    requiresQualityIntegration: true,
  },
  {
    to: (projectId: string) => `/projects/${projectId}/settings`,
    label: "Settings  ",
    icon: icons.IconSettings,
  },
  // {
  //   link: (projectId: string) => `/projects/${projectId}/availability-analysis`,
  //   label: "Availability Analysis",
  //   icon: icons.IconClockHour5,
  //   underDevelopment: true,
  // },
  // {
  //   link: (projectId: string) => `/projects/${projectId}/data`,
  //   label: "Data",
  //   icon: icons.IconChartAreaLineFilled,
  // },
  // {
  //   link: (projectId: string) => `/projects/${projectId}/capacity`,
  //   label: "Capacity",
  //   icon: icons.IconBattery3,
  //   bessOnly: true,
  // },
];

export const profileLinks: DropdownLink[] = [
  {
    to: "/account",
    label: "Account",
    icon: icons.IconUser,
  },
  {
    to: "/settings",
    label: "Settings",
    icon: icons.IconSettings,
  },
  {
    to: "/api",
    label: "API",
    icon: icons.IconCode,
  },
];

export const developmentLinks: DropdownLink[] = [
  {
    to: "/development",
    label: "Map",
    icon: icons.IconMap,
  },
  {
    to: "/development/resources",
    label: "Resources",
    icon: icons.IconChartTreemap,
  },
  {
    to: "/development/prices",
    label: "Prices",
    icon: icons.IconLocationDollar,
  },
];
