// TODO:
// - Add virtualization to ScrollAreas
// - Add units display to lower ScrollArea

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Group,
  Paper,
  ScrollArea,
  SegmentedControl,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconChevronDown, IconTag } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";

import CustomCard from "@/components/CustomCard";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetDevices,
  useGetDeviceTypes,
  useGetProject,
  useGetSensorTypes,
  useGetTags,
  useGetTimeSeries,
} from "@/hooks/api";
import { DataTimeSeries } from "@/hooks/types";
import { AdvancedDatePicker } from "@/components/GIS";
import { useValidateDateRange } from "@/components/datepicker/utils";

interface Parent {
  id: number;
  name_long: string | null;
}

interface TagChild {
  id: number;
  parent_id: number;
  name_long: string | null;
  device_id: number | null;
  sensor_type_id: number | null;
  name_scada: string | null;
  unit: string | null;
}

function dataToCSV(data: DataTimeSeries[]) {
  if (data.length === 0) return "";

  const timestamps = data[0].x;
  const headers = ["Timestamp", ...data.map((sensor) => sensor.tag_name_scada)];
  const csvRows = [headers.join(",")];

  timestamps.forEach((timestamp, index) => {
    const row = [timestamp];
    data.forEach((sensor) => {
      const value = sensor.y[index];
      row.push(value.toFixed(4));
    });
    csvRows.push(row.join(","));
  });

  return csvRows.join("\n");
}

function downloadCSV(csv: string, filename: string) {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function handleDownload(data: DataTimeSeries[]) {
  const csvData = dataToCSV(data);
  if (csvData) {
    downloadCSV(csvData, "sensor-data.csv");
  }
}

const ParentItem = React.memo(
  ({
    parent,
    childrenTags,
    isExpanded,
    onToggleExpand,
    onParentCheck,
    onChildCheck,
    checkedChildren,
    tagDisplay,
  }: {
    parent: Parent;
    childrenTags: TagChild[];
    isExpanded: boolean;
    onToggleExpand: (id: number) => void;
    onParentCheck: (id: number) => void;
    onChildCheck: (id: number) => void;
    checkedChildren: Record<number, boolean>;
    tagDisplay: string;
  }) => {
    const checkedCount = childrenTags.filter(
      (child) => checkedChildren[child.id],
    ).length;
    const isIndeterminate =
      checkedCount > 0 && checkedCount < childrenTags.length;
    const isParentChecked = checkedCount === childrenTags.length;

    return (
      <Stack key={parent.id} gap={0}>
        <Group gap={2}>
          <Checkbox
            checked={isParentChecked}
            indeterminate={isIndeterminate}
            onChange={() => onParentCheck(parent.id)}
          />
          <Group
            onClick={() => onToggleExpand(parent.id)}
            gap={2}
            style={{ cursor: "pointer" }}
          >
            <Text size="sm">{parent.name_long}</Text>
            <IconChevronDown
              size={14}
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </Group>
        </Group>

        {isExpanded && (
          <Stack style={{ paddingLeft: "18px" }} gap={0}>
            {childrenTags.map((tag) => (
              <Group gap={2} key={tag.id}>
                <Checkbox
                  checked={checkedChildren[tag.id] || false}
                  onChange={() => onChildCheck(tag.id)}
                />
                <IconTag size={14} />
                <Text size="sm">
                  {tagDisplay === "device_name"
                    ? tag.name_long
                    : tag.name_scada}
                </Text>
              </Group>
            ))}
          </Stack>
        )}
      </Stack>
    );
  },
);

const SelectedTagsList = React.memo(
  ({
    selectedTags,
    checkedChildren,
    onChildCheck,
    tagDisplay,
  }: {
    selectedTags: TagChild[];
    checkedChildren: Record<number, boolean>;
    onChildCheck: (id: number) => void;
    tagDisplay: string;
  }) => (
    <ScrollArea style={{ height: "100%", overflowY: "auto" }}>
      {selectedTags.map((tag) => (
        <Group gap={2} key={tag.id}>
          <Checkbox
            checked={checkedChildren[tag.id] || false}
            onChange={() => onChildCheck(tag.id)}
          />
          <Text size="sm">
            {tagDisplay === "device_name" ? tag.name_long : tag.name_scada}
          </Text>
        </Group>
      ))}
    </ScrollArea>
  ),
);

const ProjectData = () => {
  const { projectId = "-1" } = useParams();

  useEffect(() => {
    setCheckedParents({});
    setCheckedChildren({});
    params.delete("selectedTagIds");
  }, [projectId]);

  const [expandedIds, setExpandedIds] = useState<number[]>([]);
  const [checkedParents, setCheckedParents] = useState<Record<number, boolean>>(
    {},
  );
  const [checkedChildren, setCheckedChildren] = useState<
    Record<number, boolean>
  >({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  // Retrieve parameters from the URL
  const params = new URLSearchParams(window.location.search);
  const urlTagDisplay = params.get("tagDisplay");
  const urlParentType = params.get("parentType");
  const urlSelectedDeviceType = params.get("selectedDeviceType");
  const urlSelectedTagIds = params.get("selectedTagIds");

  // Initialize state with URL parameters or default values
  const [tagDisplay, setTagDisplay] = useState<string>(
    urlTagDisplay || "device_name",
  );
  const [parentType, setParentType] = useState<string>(
    urlParentType || "device",
  );
  const [selectedDeviceType, setSelectedDeviceType] = useState<string | null>(
    urlSelectedDeviceType || null,
  );

  const initialSelectedTagIds = urlSelectedTagIds
    ? urlSelectedTagIds.split(",").map(Number)
    : [];
  useEffect(() => {
    if (initialSelectedTagIds.length > 0) {
      const initialCheckedChildren = initialSelectedTagIds.reduce(
        (acc, id) => ({ ...acc, [id]: true }),
        {},
      );
      setCheckedChildren(initialCheckedChildren);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { start, end } = useValidateDateRange({});

  // Update URL params whenever these dependencies change
  useEffect(() => {
    const updateParams = new URLSearchParams(window.location.search);
    updateParams.set("tagDisplay", tagDisplay);
    updateParams.set("parentType", parentType);

    if (selectedDeviceType) {
      updateParams.set("selectedDeviceType", selectedDeviceType);
    } else {
      updateParams.delete("selectedDeviceType");
    }

    const selectedTagIds = Object.keys(checkedChildren)
      .filter((key) => checkedChildren[Number(key)])
      .map(Number);
    if (selectedTagIds.length > 0) {
      updateParams.set("selectedTagIds", selectedTagIds.join(","));
    } else {
      updateParams.delete("selectedTagIds");
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${updateParams}`,
    );
  }, [tagDisplay, parentType, selectedDeviceType, checkedChildren, start, end]);

  // API Data
  const project = useGetProject({ pathParams: { projectId } });

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  if (project.data) {
    if (start) {
      startQuery = start.tz(project.data.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.data.time_zone, true).toISOString();
    }
  }

  const { data: deviceTypes, isLoading: isDeviceTypesLoading } =
    useGetDeviceTypes({
      queryParams: { project_id: projectId },
    });
  const { data: sensorTypes, isLoading: isSensorTypesLoading } =
    useGetSensorTypes({
      queryParams: { project_id: projectId },
    });
  const { data: devices, isLoading: isDevicesLoading } = useGetDevices({
    pathParams: { projectId },
  });
  const { data: tags, isLoading: isTagsLoading } = useGetTags({
    pathParams: { projectId },
  });

  const filteredDeviceTypes = useMemo(
    () =>
      deviceTypes?.filter(
        (d) =>
          d.device_type_id !== 0 &&
          devices?.some((device) => device.device_type_id === d.device_type_id),
      ) || [],
    [deviceTypes, devices],
  );

  // All tags mapped to TagChild structure
  const tagChildren: TagChild[] = useMemo(() => {
    if (!tags || !sensorTypes || !devices) return [];
    return tags.map((tag) => {
      const sensorType = sensorTypes.find(
        (s) => s.sensor_type_id === tag.sensor_type_id,
      );
      const device = devices.find((d) => d.device_id === tag.device_id);
      const nameLong =
        tag.sensor_type_id === 24 || tag.sensor_type_id === 25
          ? tag.name_long
          : `${sensorType?.name_long || ""} ${device?.name_long || ""}`.trim();

      const parentId =
        parentType === "device"
          ? (tag.device_id ?? -1)
          : (tag.sensor_type_id ?? -1);

      return {
        id: tag.tag_id,
        parent_id: parentId,
        name_long: nameLong || null,
        device_id: tag.device_id || null,
        sensor_type_id: tag.sensor_type_id || null,
        name_scada: tag.name_scada || null,
        unit: sensorType?.unit || null,
      };
    });
  }, [tags, sensorTypes, devices, parentType]);

  // Filtered tags based on selectedDeviceType and search
  const filteredTags: TagChild[] = useMemo(() => {
    if (!selectedDeviceType || !devices || !tags || !sensorTypes) return [];

    const relevantDevices = devices.filter(
      (device) => device.device_type_id.toString() === selectedDeviceType,
    );
    const deviceIds = relevantDevices.map((d) => d.device_id);

    const baseTags = tags.filter(
      (tag) =>
        tag.sensor_type_id &&
        tag.device_id &&
        deviceIds.includes(tag.device_id),
    );

    const mappedTags = baseTags.map((tag) => {
      const sensorType = sensorTypes.find(
        (s) => s.sensor_type_id === tag.sensor_type_id,
      );
      const device = devices.find((d) => d.device_id === tag.device_id);
      const nameLong =
        tag.sensor_type_id === 24 || tag.sensor_type_id === 25
          ? tag.name_long + " " + sensorType?.name_long
          : `${sensorType?.name_long || ""} ${device?.name_long || ""}`.trim();
      const parentId =
        parentType === "device" ? tag.device_id! : tag.sensor_type_id!;

      return {
        id: tag.tag_id,
        parent_id: parentId,
        name_long: nameLong || "none",
        device_id: tag.device_id,
        sensor_type_id: tag.sensor_type_id,
        name_scada: tag.name_scada,
        unit: sensorType?.unit || null,
      };
    });

    if (!debouncedSearchTerm) return mappedTags;

    try {
      const regex = new RegExp(debouncedSearchTerm, "i");
      return mappedTags.filter((tag) => regex.test(tag.name_long || ""));
    } catch (e) {
      console.error("Invalid regular expression:", e);
      return [];
    }
  }, [
    tags,
    sensorTypes,
    devices,
    debouncedSearchTerm,
    parentType,
    selectedDeviceType,
  ]);

  const selectedTagIds = useMemo(
    () =>
      Object.keys(checkedChildren)
        .filter((key) => checkedChildren[Number(key)])
        .map(Number),
    [checkedChildren],
  );

  const selectedTags = useMemo(() => {
    const filtered = tagChildren.filter((t) => selectedTagIds.includes(t.id));
    return filtered.sort((a, b) =>
      (a.name_long || "").localeCompare(b.name_long || ""),
    );
  }, [tagChildren, selectedTagIds]);

  const filteredParents: Parent[] = useMemo(() => {
    if (!selectedDeviceType || !devices || !tags || !sensorTypes) return [];

    const relevantDevices = devices.filter(
      (device) => device.device_type_id.toString() === selectedDeviceType,
    );
    const deviceIds = relevantDevices.map((d) => d.device_id);

    if (parentType === "device") {
      const parents = relevantDevices
        .filter((device) =>
          filteredTags.some((tag) => tag.parent_id === device.device_id),
        )
        .map((device) => ({
          id: device.device_id,
          name_long: device.name_full || "",
        }));
      return parents;
    } else {
      // parentType === "sensor"
      const tagsForDevices = tags.filter(
        (tag) =>
          tag.sensor_type_id &&
          tag.device_id &&
          deviceIds.includes(tag.device_id),
      );

      const sensorTypeIds = Array.from(
        new Set(
          tagsForDevices
            .map((t) => t.sensor_type_id)
            .filter((id) => id !== null),
        ),
      ) as number[];

      const parents = sensorTypes
        .filter((sensorType) =>
          sensorTypeIds.includes(sensorType.sensor_type_id),
        )
        .map((sensorType) => ({
          id: sensorType.sensor_type_id,
          name_long: sensorType.name_long || "",
        }));
      return parents;
    }
  }, [
    devices,
    sensorTypes,
    selectedDeviceType,
    filteredTags,
    parentType,
    tags,
  ]);

  const {
    data: timeSeriesData,
    isLoading: timeSeriesIsLoading,
    refetch,
  } = useGetTimeSeries({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      tag_ids: selectedTags?.map((tag) => tag.id),
      start: startQuery,
      end: endQuery,
    },
    queryOptions: { enabled: false },
  });

  // Handlers
  const handleParentCheckboxChange = useCallback(
    (parentId: number) => {
      const newCheckedState = !checkedParents[parentId];
      setCheckedParents((prev) => ({ ...prev, [parentId]: newCheckedState }));

      const children = filteredTags.filter((tag) => tag.parent_id === parentId);
      const newCheckedChildren = { ...checkedChildren };
      children.forEach((child) => {
        newCheckedChildren[child.id] = newCheckedState;
      });
      setCheckedChildren(newCheckedChildren);
    },
    [checkedParents, checkedChildren, filteredTags],
  );

  const handleChildCheckboxChange = useCallback((childId: number) => {
    setCheckedChildren((prev) => ({ ...prev, [childId]: !prev[childId] }));
  }, []);

  const toggleParentExpansion = useCallback((parentId: number) => {
    setExpandedIds((prev) =>
      prev.includes(parentId)
        ? prev.filter((id) => id !== parentId)
        : [...prev, parentId],
    );
  }, []);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    [],
  );

  const handleSelectAll = useCallback(() => {
    const newCheckedParents: Record<number, boolean> = {};
    const newCheckedChildren: Record<number, boolean> = {};
    filteredParents.forEach((parent) => {
      newCheckedParents[parent.id] = true;
      const children = filteredTags.filter(
        (tag) => tag.parent_id === parent.id,
      );
      children.forEach((child) => {
        newCheckedChildren[child.id] = true;
      });
    });
    setCheckedParents(newCheckedParents);
    setCheckedChildren(newCheckedChildren);
  }, [filteredParents, filteredTags]);

  const handleClearAll = useCallback(() => {
    setCheckedParents({});
    setCheckedChildren({});
  }, []);

  const handleExpandAll = useCallback(() => {
    setExpandedIds(filteredParents.map((parent) => parent.id));
  }, [filteredParents]);

  const handleCollapseAll = useCallback(() => {
    setExpandedIds([]);
  }, []);

  const uniqueUnits = useMemo(() => {
    return Array.from(new Set(selectedTags.map((item) => item.unit)));
  }, [selectedTags]);

  const unitGroups = useMemo(() => {
    if (!uniqueUnits || uniqueUnits.length === 0) return [];

    const colors = [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];

    return uniqueUnits.map((unit, index) => {
      const itemsWithUnit = selectedTags.filter((item) => item.unit === unit);
      return {
        unit: unit || "",
        color:
          uniqueUnits.length > 1 ? colors[index % colors.length] : undefined,
        data: itemsWithUnit.map((item) => {
          const foundSeries = timeSeriesData?.find(
            (d) => d.tag_name_scada === item.name_scada,
          );
          return {
            x: foundSeries?.x || [],
            y: foundSeries?.y || [],
            name: item.name_scada,
            hoverlabel: { namelength: -1 },
            yaxis: `y${index + 1}`,
            line: {
              color:
                uniqueUnits.length > 1
                  ? colors[index % colors.length]
                  : undefined,
            },
          };
        }),
      };
    });
  }, [selectedTags, timeSeriesData, uniqueUnits]);

  const layout = useMemo(() => {
    if (!uniqueUnits || uniqueUnits.length === 0 || !unitGroups) {
      return {
        xaxis: { domain: [0.05, 0.95] },
        yaxis: { title: "", showgrid: false },
      };
    }

    const baseLayout: any = {
      xaxis: { domain: [0.05, 0.95] },
      yaxis: {
        title: uniqueUnits[0] || "",
        linecolor: unitGroups[0]?.color,
        showgrid: false,
        tickfont: { color: unitGroups[0]?.color },
        titlefont: { color: unitGroups[0]?.color },
      },
    };

    if (unitGroups.length > 1) {
      const additionalAxes = unitGroups.slice(1).reduce(
        (acc, group, idx) => {
          acc[`yaxis${idx + 2}`] = {
            title: group.unit,
            overlaying: "y",
            side: idx % 2 === 0 ? "right" : "left",
            autoshift: true,
            anchor: "free",
            showgrid: false,
            tickfont: { color: group.color },
            titlefont: { color: group.color },
          };
          return acc;
        },
        {} as Record<string, any>,
      );
      return { ...baseLayout, ...additionalAxes };
    }

    return baseLayout;
  }, [unitGroups, uniqueUnits]);

  const isLoading =
    !project.data ||
    isDeviceTypesLoading ||
    isDevicesLoading ||
    isTagsLoading ||
    isSensorTypesLoading;

  useEffect(() => {
    if (
      urlTagDisplay &&
      urlParentType &&
      urlSelectedDeviceType &&
      urlSelectedTagIds &&
      start &&
      end &&
      !isLoading &&
      isInitialLoad
    ) {
      if (selectedTags.length > 0) {
        refetch();
      }
      setIsInitialLoad(false);
    } else if (!isLoading && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [
    urlTagDisplay,
    urlParentType,
    urlSelectedDeviceType,
    urlSelectedTagIds,
    start,
    end,
    isLoading,
    isInitialLoad,
    selectedTags,
    refetch,
    checkedParents,
  ]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Group h="100%" w="100%" gap={0}>
      <Stack p="md" pr={0} h="100%" flex={1} gap="sm">
        <TextInput
          label="Search"
          description="*Supports regular expressions"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <SegmentedControl
          data={[
            { label: "Device Name", value: "device_name" },
            { label: "SCADA Name", value: "scada_name" },
          ]}
          value={tagDisplay}
          onChange={setTagDisplay}
        />

        <Group grow>
          <Select
            data={filteredDeviceTypes.map((device) => ({
              label: device.name_long || "",
              value: device.device_type_id.toString(),
            }))}
            placeholder="Select Device Type..."
            clearable
            value={selectedDeviceType}
            onChange={setSelectedDeviceType}
            searchable
          />
          <SegmentedControl
            data={[
              { label: "Device", value: "device" },
              { label: "Sensor", value: "sensor" },
            ]}
            value={parentType}
            onChange={setParentType}
          />
        </Group>

        <Paper p="md" withBorder style={{ flex: 1, overflow: "hidden" }}>
          <ScrollArea style={{ height: "100%", overflowY: "auto" }}>
            {filteredParents.map((parent) => {
              const childrenTags = filteredTags
                .filter((tag) => tag.parent_id === parent.id)
                .sort((a, b) =>
                  (a.name_long || "").localeCompare(b.name_long || ""),
                );

              return (
                childrenTags.length > 0 && (
                  <ParentItem
                    key={parent.id}
                    parent={parent}
                    childrenTags={childrenTags}
                    isExpanded={expandedIds.includes(parent.id)}
                    onToggleExpand={toggleParentExpansion}
                    onParentCheck={handleParentCheckboxChange}
                    onChildCheck={handleChildCheckboxChange}
                    checkedChildren={checkedChildren}
                    tagDisplay={tagDisplay}
                  />
                )
              );
            })}
          </ScrollArea>
        </Paper>

        <Group gap="sm" grow>
          <Button size="compact-xs" onClick={handleExpandAll}>
            Expand All
          </Button>
          <Button size="compact-xs" onClick={handleCollapseAll}>
            Collapse All
          </Button>
        </Group>

        <Button size="compact-xs" onClick={handleSelectAll}>
          Select All
        </Button>

        <Paper p="md" withBorder style={{ flex: 1, overflow: "hidden" }}>
          <SelectedTagsList
            selectedTags={selectedTags}
            checkedChildren={checkedChildren}
            onChildCheck={handleChildCheckboxChange}
            tagDisplay={tagDisplay}
          />
        </Paper>

        <Button size="compact-xs" onClick={handleClearAll}>
          Clear All
        </Button>
        <Group justify="center" gap="sm">
          <AdvancedDatePicker includeClearButton={false} />
        </Group>

        <Button
          size="compact-xs"
          onClick={() => refetch()}
          disabled={selectedTags.length === 0}
        >
          Fetch Data
        </Button>
      </Stack>

      <Stack p="md" h="100%" flex={3}>
        <CustomCard style={{ height: "100%" }}>
          <PlotlyPlot
            data={unitGroups.flatMap(
              (group) =>
                group.data?.map((item) => ({
                  ...item,
                  name: item.name || "Unnamed",
                })) || [],
            )}
            layout={layout}
            isLoading={timeSeriesIsLoading}
          />
        </CustomCard>
        <Button
          size="compact-xs"
          onClick={() => handleDownload(timeSeriesData || [])}
          disabled={!timeSeriesData}
        >
          Download Data
        </Button>
      </Stack>
    </Group>
  );
};

export default React.memo(ProjectData);
