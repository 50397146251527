import CustomCard from "@/components/CustomCard";
import { AdvancedDatePicker } from "@/components/GIS";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetProject,
  useGetProjectEnergyProductionKPIData,
  useGetTags,
} from "@/hooks/api";
import { downloadCSV } from "@/utils";
import { Button, Group, Stack, Title, useMantineTheme } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";

const ProjectKPIProjectEnergyProduction = () => {
  const theme = useMantineTheme();

  const { projectId } = useParams();
  const [searchParams] = useSearchParams();

  // Get project data
  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: {
      enabled: projectId !== undefined,
      staleTime: Infinity,
    },
  });

  // Parse search params
  let start: string | undefined = undefined;
  let end: string | undefined = undefined;
  if (project.data) {
    if (searchParams.get("start")) {
      start = dayjs(searchParams.get("start"))
        .tz(project.data.time_zone, true)
        .toISOString();
    }
    if (searchParams.get("end")) {
      end = dayjs(searchParams.get("end"))
        .add(1, "day")
        .tz(project.data.time_zone, true)
        .toISOString();
    }
  }

  // Get KPI data
  const data = useGetProjectEnergyProductionKPIData({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      start: start,
      end: end,
    },
    queryOptions: {
      enabled:
        project.data !== undefined && start !== undefined && end !== undefined,
    },
  });

  // Get tag data
  const tag = useGetTags({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      sensor_type_ids: [1],
    },
  });

  const download = () => {
    if (data.data === undefined || project.data === undefined) {
      return;
    }

    const headers = ["Date", "Energy"];
    const dataValues = data.data.data;
    const indexValues = data.data.index;
    const filename = `${project.data.name_long} - Energy Production - ${dayjs(
      searchParams.get("start"),
    ).format("YYYY-MM-DD")}-${dayjs(searchParams.get("end")).format(
      "YYYY-MM-DD",
    )}.csv`;

    // Convert the data to CSV format
    const rows = [
      headers.join(","),
      ...dataValues.map((row, i) => [indexValues[i], ...row].join(",")),
    ];

    // Add a first row that says when the report was generated
    rows.unshift("");
    rows.unshift(`Report generated at ${dayjs().toISOString()}`);

    const csv = rows.join("\n");

    downloadCSV(csv, filename);
  };

  const downloadEnabled = data.data !== undefined && project.data !== undefined;
  const tagInfo =
    tag.data && `Derived from SCADA tag ${tag.data[0].name_scada}`;

  return (
    <Stack p="md" h="100%" w="100%">
      <Title order={1}>Project Energy Production</Title>
      <Group>
        <AdvancedDatePicker
          includeClearButton={false}
          defaultRange="past-week"
        />
        <Group>
          <Button
            variant="gradient"
            disabled={!downloadEnabled}
            gradient={{ from: "gray", to: theme.primaryColor, deg: 180 }}
            rightSection={<IconDownload size={14} />}
            onClick={() => downloadEnabled && download()}
          >
            Download
          </Button>
        </Group>
      </Group>
      <CustomCard
        title="Energy Production"
        info={tagInfo}
        style={{ flex: 1, width: "100%" }}
      >
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.index,
                y: data.data.data.map((d) => d[0]),
                type: "scatter",
                mode: "lines",
              },
            ]
          }
          layout={{
            yaxis: {
              title: "MWh",
              rangemode: "tozero",
            },
          }}
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
    </Stack>
  );
};

export default ProjectKPIProjectEnergyProduction;
