import { useMemo } from "react";
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table";

import { Link } from "react-router-dom";

import CustomCard from "@/components/CustomCard";
import { Stack } from "@mantine/core";

import { useGetResources } from "@/hooks/api";
import { Resource } from "@/hooks/types";

const Home = () => {
  const columns = useMemo<MRT_ColumnDef<Resource>[]>(
    () => [
      {
        accessorKey: "name_long",
        header: "Name",
        Cell: ({ row }) => (
          <Link
            to={`/development/resources/${row.original.resource_id}`}
            style={{ color: "inherit" }}
          >
            {row.original.name_long}
          </Link>
        ),
      },
      {
        accessorKey: "capacity_power",
        header: "Power Capacity (MW)",
      },
      {
        accessorKey: "qse.name_long",
        header: "QSE",
      },
      {
        accessorKey: "dme.name_long",
        header: "DME",
      },
      {
        accessorKey: "settlement_point.name",
        header: "Settlement Point",
      },
      {
        accessorKey: "county",
        header: "County",
      },
    ],
    [],
  );

  const { data } = useGetResources({ queryParams: { deep: true } });

  const fetchedData = data ?? [];

  const table = useMantineReactTable({
    columns,
    data: fetchedData,
    enableHiding: false,
    enableRowVirtualization: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    mantinePaperProps: {
      shadow: undefined,
      style: {
        border: "none",
        borderRadius: "inherit",
        height: "100%",
      },
    },
  });

  return (
    <Stack h="100%" p="md">
      <CustomCard
        title="ERCOT Energy Storage Resources"
        fill
        style={{ height: "100%" }}
      >
        <MantineReactTable table={table} />
      </CustomCard>
    </Stack>
  );
};

export default Home;
