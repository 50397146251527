import { AdvancedDatePicker } from "@/components/GIS";
import { PageLoader } from "@/components/Loading";
import {
  useGetDevices,
  useGetPaginatedEvents,
  useGetProject,
} from "@/hooks/api";
import { EventSummary } from "@/hooks/types";
import {
  ActionIcon,
  Button,
  Group,
  HoverCard,
  MultiSelect,
  SegmentedControl,
  Select,
  Stack,
  Switch,
  Text,
  Title,
} from "@mantine/core";
import {
  IconCaretLeft,
  IconCaretRight,
  IconInfoCircle,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  default as relativeTime,
  default as utc,
} from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import {
  MantineReactTable,
  createMRTColumnHelper,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const ProjectEvents = () => {
  const { projectId } = useParams();
  const [showEnergy, setShowEnergy] = useState<string>("dollar");
  const [page, setPage] = useState<number>(() => {
    const params = new URLSearchParams(window.location.search);
    const pageParam = params.get("page");
    return pageParam ? parseInt(pageParam) : 1; // Read from URI or default to 1
  });
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [showClosed, setShowClosed] = useState<boolean>(() => {
    const params = new URLSearchParams(window.location.search);
    const showClosedParam = params.get("showClosed");
    return showClosedParam === "true"; // Read from URI or default to false
  });

  const { data: project } = useGetProject({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });

  const { data: allDevices } = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });
  const sortedDevices = allDevices
    ?.filter(
      (device) => device.name_full !== null && device.device_type_id !== 0,
    )
    .sort((a, b) => {
      if (a.device_type_id === b.device_type_id) {
        return a?.name_full?.localeCompare(b?.name_full ?? "") ?? 0;
      }
      return a.device_type_id - b.device_type_id;
    });

  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<string[]>(
    () => {
      const params = new URLSearchParams(window.location.search);
      return params.get("deviceTypeIds")?.split(",") || [];
    },
  );
  const [selectedDevices, setSelectedDevices] = useState<string[]>(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("deviceIds")?.split(",") || [];
  });

  const searchParams = new URLSearchParams(window.location.search);
  let start = searchParams.get("start");
  let end = searchParams.get("end");
  if (start && end && project) {
    start = dayjs(start).tz(project.time_zone, true).toISOString();
    end = dayjs(end).add(1, "day").tz(project.time_zone, true).toISOString();
  }

  const [selectedSort, setSelectedSort] = useState<string>("time_start");
  const [selectedOrder, setSelectedOrder] = useState<string>("desc");

  // Instead of a conventional refetch, we are using a state to trigger queryOptions enabled prop.
  // See: https://tanstack.com/query/latest/docs/framework/react/guides/disabling-queries#lazy-queries
  const [queryParams, setQueryParams] = useState({
    page: page - 1,
    page_size: rowsPerPage,
    open: !showClosed,
    sort_column: selectedSort,
    sort_direction: selectedOrder,
    device_type_id: selectedDeviceTypes.map(Number),
    device_ids: selectedDevices.map(Number),
    start: start,
    end: end,
    updated: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (selectedDeviceTypes.length > 0) {
      params.set("deviceTypeIds", selectedDeviceTypes.join(","));
    } else {
      params.delete("deviceTypeIds");
    }
    const newSearch = params.toString();
    const newPath = newSearch ? `?${newSearch}` : "";
    navigate(newPath, { replace: true });
  }, [selectedDeviceTypes, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (selectedDevices.length > 0) {
      params.set("deviceIds", selectedDevices.join(","));
    } else {
      params.delete("deviceIds");
    }
    const newSearch = params.toString();
    const newPath = newSearch ? `?${newSearch}` : "";
    navigate(newPath, { replace: true });
  }, [selectedDevices, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString()); // Update the URI with the current page
    const newSearch = params.toString();
    const newPath = newSearch ? `?${newSearch}` : "";
    navigate(newPath, { replace: true });
  }, [page, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set("showClosed", showClosed.toString()); // Update the URI with the current showClosed
    const newSearch = params.toString();
    const newPath = newSearch ? `?${newSearch}` : "";
    navigate(newPath, { replace: true });
  }, [showClosed, navigate]);

  const { data: eventsPaginated, isLoading: eventsPaginatedLoading } =
    useGetPaginatedEvents({
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        page: queryParams.page,
        page_size: queryParams.page_size,
        open: queryParams.open,
        sort_column: queryParams.sort_column,
        sort_direction: queryParams.sort_direction,
        device_type_ids: queryParams.device_type_id.map(Number),
        device_ids: queryParams.device_ids.map(Number),
        start: queryParams.start ?? undefined,
        end: queryParams.end ?? undefined,
      },
      queryOptions: {
        enabled: queryParams.updated,
      },
    });

  const handleFetch = async () => {
    try {
      setQueryParams({
        page: page - 1,
        page_size: rowsPerPage,
        open: !showClosed,
        sort_column: selectedSort,
        sort_direction: selectedOrder,
        device_type_id: selectedDeviceTypes.map(Number),
        device_ids: selectedDevices.map(Number),
        start: start,
        end: end,
        updated: true,
      });
    } catch (error) {
      console.error("Error fetching EventsPaginated:", error);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [page, showClosed]);

  useEffect(() => {
    if (!queryParams.updated) {
      setQueryParams({
        page: page - 1,
        page_size: rowsPerPage,
        open: !showClosed,
        sort_column: selectedSort,
        sort_direction: selectedOrder,
        device_type_id: selectedDeviceTypes.map(Number),
        device_ids: selectedDevices.map(Number),
        start: start,
        end: end,
        updated: false,
      });
    }
  }, [queryParams]);

  const hasData = (eventsPaginated?.length ?? 0) > 0;
  const hasProject = !!project;
  const hasBoth = hasData && hasProject;

  const columnHelper = createMRTColumnHelper<EventSummary>();

  const handleClickNext = () => {
    setPage(page + 1);
    handleFetch();
  };
  const handleClickPrev = () => {
    setPage(page - 1);
    handleFetch();
  };
  const handleChangeRowCount = (row_count: number) => {
    setRowsPerPage(row_count);
  };

  const columns = useMemo(() => {
    if (!hasBoth || !eventsPaginated) {
      return [];
    }
    return [
      columnHelper.accessor("device_name_full", {
        header: "Device",
        Cell: ({ row }) => (
          <Link
            to={`/projects/${projectId}/events/event/?eventId=${row.original.event_id}`}
            style={{ color: "inherit" }}
          >
            {row.original.device_name_full}
          </Link>
        ),
      }),
      columnHelper.accessor(
        (row) => {
          return dayjs(row.time_start)
            .tz(project.time_zone)
            .format("YYYY-MM-DD HH:mm");
        },
        {
          id: "time_start",
          header: "Start Time",
        },
      ),
      columnHelper.accessor(
        (row) => {
          if (!row.time_end) {
            return;
          }
          return dayjs(row.time_end)
            .tz(project.time_zone)
            .format("YYYY-MM-DD HH:mm");
        },
        {
          header: "End Time",
          enableSorting: false,
        },
      ),
      columnHelper.accessor(
        (row) => {
          if (row.loss_daily_power === 0) {
            return "-";
          } else {
            if (showEnergy === "mwh") {
              return (row.loss_daily_power ?? 0).toFixed(2);
            } else {
              return `$${(row.loss_daily_financial ?? 0).toFixed(2)}`;
            }
          }
        },
        {
          header: "Loss - Daily Average",
          enableSorting: true,
          mantineTableHeadCellProps: {
            align: "center",
          },
          mantineTableBodyCellProps: {
            align: "center",
          },
        },
      ),

      columnHelper.accessor(
        (row) => {
          if (row.loss_today_power === 0) {
            return "-";
          } else {
            if (showEnergy === "mwh") {
              return (row.loss_today_power ?? 0).toFixed(2);
            } else {
              return `$${(row.loss_today_financial ?? 0).toFixed(2)}`;
            }
          }
        },
        {
          header: "Loss - Today",
          enableSorting: true,
          mantineTableHeadCellProps: {
            align: "center",
          },
          mantineTableBodyCellProps: {
            align: "center",
          },
        },
      ),
      columnHelper.accessor(
        (row) => {
          if (row.loss_total_power === 0) {
            return "-";
          } else {
            if (showEnergy === "mwh") {
              return (row.loss_total_power ?? 0).toFixed(2);
            } else {
              return `$${(row.loss_total_financial ?? 0).toFixed(2)}`;
            }
          }
        },
        {
          header: "Loss - Total",
          enableSorting: true,
          mantineTableHeadCellProps: {
            align: "center",
          },
          mantineTableBodyCellProps: {
            align: "center",
          },
        },
      ),

      columnHelper.accessor(
        (row) => {
          return row.root_cause ?? "Unknown";
        },
        {
          header: "Root Cause",
          enableSorting: true,
        },
      ),
    ];
  }, [hasBoth, eventsPaginated, showEnergy, project?.time_zone, projectId]);

  const table = useMantineReactTable({
    columns,
    data: eventsPaginated ?? [],
    enableStickyHeader: true,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    mantineTableContainerProps: {
      style: { maxHeight: "100%" },
    },
  });
  if (eventsPaginatedLoading) {
    return <PageLoader />;
  }

  return (
    <Stack p="md" h="100%">
      <Group justify="space-between">
        <Stack>
          <Group>
            <Title order={1}>Events</Title>
            <HoverCard shadow="md">
              <HoverCard.Target>
                <IconInfoCircle />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                A dash means the data has not yet been analyzed for loss. This
                process will complete in the next 6 hours.
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
          <Group>
            <SegmentedControl
              data={[
                { label: "$", value: "dollar" },
                { label: "MWh", value: "mwh" },
              ]}
              value={showEnergy}
              onChange={setShowEnergy}
            />
            <Switch
              label="Show Closed Events"
              defaultChecked={showClosed}
              onClick={() => {
                setShowClosed(!showClosed);
              }}
            />
          </Group>
        </Stack>

        <Stack>
          <Group justify="end">
            <Select
              data={[
                { label: "Device", value: "device_id" },
                { label: "Start Time", value: "time_start" },
                { label: "Loss - Total", value: "loss_total_financial" },
                { label: "Loss - Daily", value: "loss_daily" },
              ]}
              allowDeselect={false}
              defaultValue={"time_start"}
              label="Sort By"
              value={selectedSort}
              onChange={(value) => setSelectedSort(value ?? "time_start")}
            />
            <Select
              data={[
                { label: "Ascending", value: "asc" },
                { label: "Descending", value: "desc" },
              ]}
              allowDeselect={false}
              defaultValue={"desc"}
              value={selectedOrder}
              onChange={(value) => setSelectedOrder(value ?? "desc")}
              label="Sort Order"
            />
          </Group>
          <Group justify="end">
            <HoverCard position="top">
              <HoverCard.Target>
                <IconInfoCircle size="1.25rem" />
              </HoverCard.Target>
              <HoverCard.Dropdown w="25%">
                The Date Range will filter the events displayed to include
                events which were open at any point within the range. Note: This
                will include open Events which began before the end date of the
                Range.
              </HoverCard.Dropdown>
            </HoverCard>
            <AdvancedDatePicker includeIncrementButtons={false} />
            <MultiSelect
              data={[
                { label: "PV PCS", value: "2" },
                { label: "PV PCS Module", value: "3" },
                { label: "Meter", value: "5" },
                { label: "PV DC Combiner", value: "9" },
                { label: "PCS Tracker Group", value: "10" },
              ]}
              value={selectedDeviceTypes}
              onChange={(value) => setSelectedDeviceTypes(value)}
              placeholder={
                selectedDeviceTypes.length > 0 ? "" : "Select device types..."
              }
              style={{ minWidth: "200px" }}
              clearable
            />
            <MultiSelect
              data={sortedDevices
                ?.filter((device) => device.name_full !== null)
                .map((device) => ({
                  label: device.name_full!,
                  value: device.device_id.toString(),
                }))}
              value={selectedDevices}
              onChange={(value) => setSelectedDevices(value)}
              placeholder={
                selectedDevices.length > 0 ? "" : "Select devices..."
              }
              style={{ minWidth: "200px" }}
              searchable
              clearable
            ></MultiSelect>
            <Button w={201.33} onClick={handleFetch}>
              Refetch Data
            </Button>
          </Group>
        </Stack>
      </Group>
      <MantineReactTable table={table} />
      <Group justify="center">
        <Text>Rows per page</Text>
        <Select
          data={["5", "10", "20", "50"]}
          defaultValue={rowsPerPage.toString()}
          style={{ width: "85px" }}
          onChange={(value) =>
            handleChangeRowCount(parseInt(value ? value : "20"))
          }
        />
        <Text>{`${(page - 1) * rowsPerPage + 1} - ${page * rowsPerPage}`}</Text>
        <ActionIcon
          disabled={page == 1 ? true : false}
          onClick={handleClickPrev}
        >
          <IconCaretLeft />
        </ActionIcon>
        <ActionIcon>
          <IconCaretRight onClick={handleClickNext} />
        </ActionIcon>
      </Group>
    </Stack>
  );
};

export default ProjectEvents;
