import { PageLoader } from "@/components/Loading";
import { useGetProject } from "@/hooks/api";
import { Group, Paper, Stack, Text, Title } from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import styles from "./ProjectReports.module.css";

const Page = () => {
  const { projectId } = useParams();
  const { data: project, isLoading: isProjectLoading } = useGetProject({
    pathParams: { projectId: projectId || "" },
  });

  if (isProjectLoading) return <PageLoader />;

  const reports = [
    ...(project?.has_pv_dc_combiners
      ? [
          {
            name: "DC Amperage Report",
            component: <DCAmperageReport />,
            link: `/projects/${projectId}/reports/dc-amperage`,
          },
        ]
      : []),
    ...(project?.project_id === "6970fba7-6462-475f-805a-2357ee4ababb"
      ? [
          {
            name: "Module Degradation Report",
            component: <ModuleDegradationReport />,
            link: `/projects/${projectId}/reports/module-degradation`,
          },
        ]
      : []),
  ];

  return (
    <Stack p="xs" h="100%">
      <Title order={1}>Reports</Title>
      <Group>
        {reports.map((report, index) => (
          <Link
            to={report.link}
            key={index}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Paper
              withBorder
              key={index}
              p="md"
              radius="md"
              className={styles.element}
              style={{ cursor: "pointer" }}
              w={297.5}
              h={385}
              shadow="md"
            >
              <Stack w="100%" align="center">
                <Title order={4}>{report.name}</Title>
                <Text size="sm">{report.component}</Text>
              </Stack>
            </Paper>
          </Link>
        ))}
      </Group>
    </Stack>
  );
};

const DCAmperageReport = () => {
  return (
    <Text>
      This report is designed to characterize the normalized output current of
      combiners at a project. The analysis is generated with user input for
      clearsky data, then combiner current is normalized based on relative
      capacity and compared to both neighboring and project-wide combiner
      results.
      <br />
      <br />
      Data output is available for download in XLSX format.
    </Text>
  );
};

const ModuleDegradationReport = () => {
  return (
    <Text>
      This report is designed to characterize the performance of the modules at
      the most granular level possible. The analysis is generated by heavily
      filtering data for clearsky, high-performance timestamps which guarantee
      that shortfalls can be attributed to module degradation or other DC
      performance issues.
      <br />
      <br />
      Data output is available for download in CSV format.
    </Text>
  );
};

export default Page;
