import CustomCard from "@/components/CustomCard";
import { AdvancedDatePicker, ColorBar, MapSettings } from "@/components/GIS";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { GISContext } from "@/contexts/GISContext";
import {
  useGetClearskyPOA,
  useGetDevices,
  useGetKPIData,
  useGetProject,
  useGetPvModules,
} from "@/hooks/api";
import { DataTimeSeries, Device, Project, PvModule } from "@/hooks/types";
import * as gisUtils from "@/utils/GIS";
import { findBoundingBox } from "@/utils/GIS";
import { linearRegression } from "@/utils/math";
import {
  ActionIcon,
  Box,
  Button,
  Group,
  HoverCard,
  Paper,
  ScrollArea,
  Select,
  Stack,
  Tabs,
  Text,
  Title,
  useComputedColorScheme,
} from "@mantine/core";
import {
  IconDatabasePlus,
  IconFileTypeCsv,
  IconInfoCircle,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { FeatureCollection } from "geojson";
import { sum } from "lodash";
import { Layout, Shape } from "plotly.js";
import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { Layer, MapMouseEvent, Map as ReactMap, Source } from "react-map-gl";
import { useParams } from "react-router-dom";
import { HoverInfo } from "../gis/utils";
import { useValidateDateRange } from "@/components/datepicker/utils";

interface ExportData {
  x: string[];
  y: {
    [deviceId: string]: number | null;
  }[];
}

function exportToCsv(filteredData: ExportData, devices: Device[]) {
  // Get the device IDs from the first item in `filteredData.y`
  const deviceIds = Object.keys(filteredData.y[0]);

  // Build the header row: ["Date", "deviceId1", "deviceId2", ...]
  const headers = [
    "Date",
    ...deviceIds.map(
      (deviceId) =>
        devices.find((device) => device.device_id === Number(deviceId))
          ?.name_long
    ),
  ];

  // Build each subsequent row
  // For the i-th date, grab the i-th object in `filteredData.y`,
  // then pull out values based on `deviceIds`
  const rows = filteredData.x.map((date, i) => {
    const rowValues = deviceIds.map((deviceId) =>
      filteredData.y[i][deviceId]?.toFixed(4)
    );
    return [date.split("T")[0], ...rowValues];
  });

  // Turn rows into CSV text
  const csvArray = [headers, ...rows];
  const csvContent = csvArray.map((row) => row.join(",")).join("\n");

  // Download CSV
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "module_degradation.csv";
  link.click();
}

const GisTab: React.FC<{
  averagePerCombiner: { [deviceId: string]: number | null };
  devices?: Device[];
}> = ({ averagePerCombiner, devices }) => {
  const blankMapStyle = gisUtils.useBlankMapStyle();
  const context = useContext(GISContext);
  const { showLabels, showSatellite, colorsGoodBad } = context || {};
  const [hoverInfo, setHoverInfo] = useState<HoverInfo>({
    feature: null,
    x: 0,
    y: 0,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  function CustomHoverCard({ hoverInfo }: { hoverInfo: HoverInfo }) {
    if (hoverInfo.feature === null) {
      return null;
    }

    return (
      <Paper
        p="xs"
        withBorder
        style={{
          left: hoverInfo.x,
          top: hoverInfo.y,
          position: "absolute",
          zIndex: 9,
          pointerEvents: "none",
        }}
        radius="xs"
      >
        <Text fw={700}>Combiner {hoverInfo.feature.properties?.name}</Text>
        {hoverInfo.feature.properties?.performance && (
          <Text>
            Performance:{" "}
            {(hoverInfo.feature.properties?.performance * 100).toFixed(1) + "%"}
          </Text>
        )}
      </Paper>
    );
  }

  const features = {
    type: "FeatureCollection",
    features: devices
      ?.filter((device) => device.device_type_id === 9)
      .map((device) => {
        return {
          type: "Feature",
          geometry: device.polygon,
          properties: {
            name: device.name_long,
            performance: averagePerCombiner[device.device_id] || null,
          },
        };
      }),
  } as FeatureCollection;

  const onHover = useCallback((event: MapMouseEvent) => {
    const {
      features,
      point: { x, y },
    } = event;

    const hoveredFeature = features && features[0];

    if (hoveredFeature) {
      setHoverInfo({
        feature: hoveredFeature,
        x,
        y,
      });
    } else {
      setHoverInfo({
        feature: null,
        x: 0,
        y: 0,
      });
    }
  }, []);
  const lowValue = 0.8;
  return (
    <Paper h="100%" w="100%" radius="md" pos="relative">
      <ReactMap
        initialViewState={{
          bounds: findBoundingBox(features),
          fitBoundsOptions: {
            padding: 35,
          },
        }}
        style={{
          borderRadius: "inherit",
          height: "100%",
          width: "100%",
        }}
        mapStyle={
          gisUtils.mapStyle({
            satellite: showSatellite,
            theme: computedColorScheme,
          }) ?? blankMapStyle
        }
        interactiveLayerIds={["data"]}
        onMouseMove={onHover}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
      >
        <Source id="data" type="geojson" data={features}>
          <Layer
            {...gisUtils.layerData({
              featureKey: "performance",
              colors: colorsGoodBad || [],
              lowValue: lowValue || 0,
              highValue: 1,
            })}
          />
          {showLabels && (
            <Layer {...gisUtils.layerLabel({ textField: "name" })} />
          )}
        </Source>
        {hoverInfo.feature && <CustomHoverCard hoverInfo={hoverInfo} />}
      </ReactMap>
      <Box
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 1,
          height: "100%",
        }}
        px="md"
        py={75}
      >
        <ColorBar
          gradient={gisUtils.colorBar({
            colors: colorsGoodBad || [],
          })}
          lowLabel={"80% -"}
          highLabel={" + 100%"}
        />
      </Box>
      <Box
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 1,
        }}
        px="md"
        py="xl"
      >
        <MapSettings />
      </Box>
    </Paper>
  );
};

// A small helper to group arrays by a given key function
function groupBy<T, K extends string | number>(
  array: T[],
  keyFn: (item: T) => K
): Record<K, T[]> {
  return array.reduce((acc, item) => {
    const key = keyFn(item);
    if (!acc[key]) acc[key] = [];
    acc[key].push(item);
    return acc;
  }, {} as Record<K, T[]>);
}

function shallowEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) return false;
  }

  return true;
}

const GraphsTab: React.FC<{
  filteredData: { x: string[]; y: { [deviceId: string]: number | null }[] };
  devices?: Device[];
  averagePerCombiner: { [deviceId: string]: number | null };
  project: Project;
  pvModules: PvModule[];
}> = ({ filteredData, devices, averagePerCombiner, project, pvModules }) => {
  const deviceTypes = [
    { value: "9", label: "Combiner" },
    { value: "2", label: "Inverter" },
    { value: "14", label: "Circuit" },
  ];
  const [selectedDeviceType, setSelectedDeviceType] = useState<string | null>(
    "9"
  );
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const timezone = project?.time_zone || "America/Chicago";
  const computedColorScheme = useComputedColorScheme("dark");

  // Calculated arrays
  const averagePerDay: (number | null)[] = useMemo(() => {
    let averagePerDay: (number | null)[] = [];
    if (filteredData.y) {
      averagePerDay = filteredData.y.map((item) => {
        if (item != null && typeof item === "object" && !Array.isArray(item)) {
          const values = Object.values(item).filter(
            (value): value is number => value !== null && value !== undefined
          );
          const total = sum(values);
          return values.length > 0 ? total / values.length : null;
        }
        return null;
      });
    }
    return averagePerDay;
  }, [filteredData.y]);

  const maxPerCombiner: { [deviceId: string]: number | null } = useMemo(() => {
    let maxPerCombiner: { [deviceId: string]: number | null } = {};
    if (filteredData.y) {
      maxPerCombiner = filteredData.y.reduce((acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (value != null) {
            acc[key] = Math.max(acc[key] ?? -Infinity, value);
          }
        });
        return acc;
      }, {} as { [deviceId: string]: number | null });
    }
    return maxPerCombiner;
  }, [filteredData.y]);

  const deviceMap = useMemo(() => {
    const map = new Map<number, Device>();
    devices?.forEach((device) => {
      map.set(device.device_id, device);
    });
    return map;
  }, [devices]);

  const findParentByType = (
    currentDevice: Device | undefined,
    targetTypeId: number
  ): number | null => {
    let parentId =
      currentDevice?.parent_device_id ||
      currentDevice?.parent_device_id ||
      null;
    let parentDevice = parentId ? deviceMap.get(parentId) : null;

    while (parentDevice) {
      if (parentDevice.device_type_id === targetTypeId) {
        return parentDevice.device_id;
      }
      parentId =
        parentDevice.parent_device_id || parentDevice.parent_device_id || null;
      parentDevice = parentId ? deviceMap.get(parentId) : null;
    }

    return null;
  };

  // Build lists for parent relationships
  const combinerKeys = Object.keys(averagePerCombiner).filter((key) =>
    devices?.some((device) => device.device_id === Number(key))
  );

  const combinerParents = combinerKeys.map((key) => {
    const device = deviceMap.get(Number(key));

    return {
      combinerId: device?.device_id,
      inverterId: findParentByType(device, 2),
      circuitId: findParentByType(device, 14),
    };
  });

  const inverterIds = Array.from(
    new Set(
      combinerParents
        .map((parent) => parent.inverterId)
        .filter((id): id is number => id !== null && id !== undefined)
    )
  );
  const circuitIds = Array.from(
    new Set(
      combinerParents
        .map((parent) => parent.circuitId)
        .filter((id): id is number => id !== null && id !== undefined)
    )
  );

  // Calculate averagePerInverter
  const averagePerInverter: { [inverterId: string]: number | null } =
    useMemo(() => {
      const averagePerInverter: { [inverterId: string]: number | null } = {};
      inverterIds.forEach((inverterId) => {
        const relevantCombiners = combinerParents
          .filter((cp) => cp.inverterId === inverterId)
          .map((cp) => cp.combinerId);

        let sumVal = 0;
        let countVal = 0;
        relevantCombiners.forEach((combinerId) => {
          const combinerIdStr = String(combinerId);
          const combinerAvg = averagePerCombiner[combinerIdStr];
          if (combinerAvg !== null && combinerAvg !== undefined) {
            sumVal += combinerAvg;
            countVal++;
          }
        });

        averagePerInverter[inverterId] =
          countVal > 0 ? sumVal / countVal : null;
      });
      return averagePerInverter;
    }, [combinerParents, averagePerCombiner, inverterIds]);

  // Calculate maxPerInverter based on daily mean performances
  const maxPerInverter: { [inverterId: string]: number | null } = {};
  inverterIds.forEach((id) => {
    maxPerInverter[id] = null;
  });

  if (filteredData.y && filteredData.x) {
    filteredData.y.forEach((dailyData) => {
      const dailyMeanPerInverter: { [inverterId: string]: number | null } = {};

      inverterIds.forEach((inverterId) => {
        const relevantCombiners = combinerParents
          .filter((cp) => cp.inverterId === inverterId)
          .map((cp) => cp.combinerId);

        const performances = relevantCombiners
          .map((combinerId) => dailyData[combinerId ?? 0] ?? null)
          .filter(
            (value): value is number => value !== null && value !== undefined
          );

        if (performances.length > 0) {
          const sumVal = performances.reduce((acc, val) => acc + val, 0);
          const mean = sumVal / performances.length;
          dailyMeanPerInverter[inverterId] = mean;

          if (
            maxPerInverter[inverterId] === null ||
            mean > (maxPerInverter[inverterId] ?? 0)
          ) {
            maxPerInverter[inverterId] = mean;
          }
        }
      });
    });
  }

  // Calculate averagePerCircuit
  const averagePerCircuit: { [circuitId: string]: number | null } =
    useMemo(() => {
      const averagePerCircuit: { [circuitId: string]: number | null } = {};
      circuitIds.forEach((circuitId) => {
        const relevantCombiners = combinerParents
          .filter((cp) => cp.circuitId === circuitId)
          .map((cp) => cp.combinerId);

        let sumOfAverages = 0;
        let countAvg = 0;
        relevantCombiners.forEach((combinerId) => {
          const combinerIdStr = String(combinerId);
          const combinerAvg = averagePerCombiner[combinerIdStr];
          if (combinerAvg !== null && combinerAvg !== undefined) {
            sumOfAverages += combinerAvg;
            countAvg++;
          }
        });
        averagePerCircuit[circuitId] =
          countAvg > 0 ? sumOfAverages / countAvg : null;
      });
      return averagePerCircuit;
    }, [combinerParents, averagePerCombiner, circuitIds]);

  // Calculate maxPerCircuit similarly
  const maxPerCircuit: { [circuitId: string]: number | null } = {};
  circuitIds.forEach((id) => {
    maxPerCircuit[id] = null;
  });

  if (filteredData.y && filteredData.x) {
    filteredData.y.forEach((dailyData) => {
      const dailyMeanPerCircuit: { [circuitId: string]: number | null } = {};

      circuitIds.forEach((circuitId) => {
        const relevantCombiners = combinerParents
          .filter((cp) => cp.circuitId === circuitId)
          .map((cp) => cp.combinerId);

        const performances = relevantCombiners
          .map((combinerId) => dailyData[combinerId ?? 0] ?? null)
          .filter(
            (value): value is number => value !== null && value !== undefined
          );

        if (performances.length > 0) {
          const sumVal = performances.reduce((acc, val) => acc + val, 0);
          const mean = sumVal / performances.length;
          dailyMeanPerCircuit[circuitId] = mean;

          if (
            maxPerCircuit[circuitId] === null ||
            mean > (maxPerCircuit[circuitId] ?? 0)
          ) {
            maxPerCircuit[circuitId] = mean;
          }
        }
      });
    });
  }

  // For device selection in "Device Performance"
  const selectData = useMemo(() => {
    return Object.keys(averagePerCombiner)
      .concat(Object.keys(averagePerInverter))
      .concat(Object.keys(averagePerCircuit))
      .filter((key) =>
        devices?.some((device) => device.device_id === Number(key))
      )
      .map((key) => {
        const device = devices?.find(
          (device) => device.device_id === Number(key)
        );
        return {
          value: key,
          label: device?.name_full || `Device ${key}`,
        };
      });
  }, [averagePerCombiner, averagePerInverter, averagePerCircuit, devices]);

  // For "Avg. DC Performance by combiner" hovers
  const countsPerKey = useMemo(() => {
    const counts: { [key: string]: number } = {};

    filteredData.y.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (value != null) {
          counts[key] = (counts[key] || 0) + 1;
        }
      });
    });

    return counts;
  }, [filteredData.y]);

  // For "Max DC Performance by combiner" hovers
  const maxDatePerKey = useMemo(() => {
    const maxDates: { [key: string]: string } = {};
    const maxValues: { [key: string]: number } = {};

    if (!filteredData.x || !filteredData.y) return maxDates;

    filteredData.y.forEach((item, index) => {
      const currentDate = filteredData.x[index];
      Object.entries(item).forEach(([key, value]) => {
        if (value != null) {
          if (maxValues[key] === undefined || value > maxValues[key]) {
            maxValues[key] = value;
            maxDates[key] = dayjs(currentDate)
              .tz(timezone)
              .format("MM/DD/YYYY");
          }
        }
      });
    });

    return maxDates;
  }, [filteredData.x, filteredData.y, timezone]);

  // Build histogram
  const allValues: number[] = useMemo(() => {
    return filteredData.y.flatMap((item) =>
      Object.values(item).filter(
        (value): value is number => typeof value === "number"
      )
    );
  }, [filteredData.y]);

  const binNum: number = 100;
  const minValue: number = Math.round(Math.min(...allValues) * 100) / 100;
  const binSize: number = 0.01;

  const binValues: { [key: number]: number } = {};
  for (let i = 0; i < binNum; i++) {
    const binStart = minValue + binSize * i;
    binValues[binStart] = 0;
  }
  allValues.forEach((value) => {
    let binIndex = Math.floor((value - minValue) / binSize);
    if (binIndex === binNum) {
      binIndex = binNum - 1;
    }
    const binStart = minValue + binSize * binIndex;
    binValues[binStart] += 1;
  });

  // Trendline for overall average
  const calculateTrendline = (dates: string[], values: (number | null)[]) => {
    const x = dates.map((date) => new Date(date).getTime());
    const y = values.map((val) => (val !== null ? val : 0));
    const lr = linearRegression(x, y);
    return x.map((xi) => lr.slope * xi + lr.intercept);
  };

  const trendline = useMemo(() => {
    return calculateTrendline(filteredData.x, averagePerDay);
  }, [filteredData.x, averagePerDay]);
  const trendlineChange = trendline[trendline.length - 1] - trendline[0];
  const trendlineChangeDays = dayjs(
    filteredData.x[filteredData.x.length - 1]
  ).diff(dayjs(filteredData.x[0]), "days");
  const trendlineChangePerYear = (trendlineChange / trendlineChangeDays) * 365;

  // Map module_id -> color
  const uniqueModuleIds = useMemo(() => {
    const moduleIds = devices?.map((d) => d.pv_module_id) || [];
    return Array.from(new Set(moduleIds)).sort((a, b) => (a ?? 0) - (b ?? 0));
  }, [devices]);

  const moduleIdToColorMap = useMemo(() => {
    // Plotly color set
    const plotlyColors = [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
    ];
    const map: { [moduleId: number]: string } = {};
    uniqueModuleIds.forEach((mId, index) => {
      map[mId ?? 0] = plotlyColors[index % plotlyColors.length];
    });
    return map;
  }, [uniqueModuleIds]);

  // --- BUILD PLOT DATA FOR COMBINER (AVERAGE) ---
  // We now split into multiple traces, one per module, removing "Module" from hover:
  const combinerDataForAvg = useMemo(() => {
    // Gather all combiner devices
    const combinerData = Object.keys(averagePerCombiner).map((key) => {
      const label =
        selectData.find((sd) => sd.value === key)?.label || `Device ${key}`;
      const count = countsPerKey[key] || 0;
      const device = deviceMap.get(Number(key));
      const moduleId = device?.pv_module_id;
      const moduleName =
        pvModules.find((pv) => pv.pv_module_id === moduleId)?.model ||
        "Unknown Module";
      const performance = averagePerCombiner[key] ?? null;

      return {
        deviceId: key,
        performance,
        count,
        label,
        moduleName,
        moduleId: moduleId ?? 0,
      };
    });

    // Group by module name
    const groupedByModule = groupBy(combinerData, (item) => item.moduleName);

    // Build traces: each module is a separate trace (legend entry)
    return Object.entries(groupedByModule).map(([moduleName, items]) => ({
      x: items.map((i) => i.deviceId), // device keys
      y: items.map((i) => i.performance),
      type: "scatter" as const,
      mode: "markers" as const,
      name: moduleName, // legend entry
      marker: {
        color: items.map((i) => moduleIdToColorMap[i.moduleId]),
      },
      hovertemplate:
        "%{customdata[1]}<br>" +
        "Average Value: %{y:,.1%}<br>" +
        "Count: %{customdata[0]}<extra></extra>",
      customdata: items.map((i) => [i.count, i.label]),
    }));
  }, [
    averagePerCombiner,
    selectData,
    countsPerKey,
    deviceMap,
    pvModules,
    moduleIdToColorMap,
  ]);

  // --- BUILD PLOT DATA FOR COMBINER (MAX) ---
  const combinerDataForMax = useMemo(() => {
    // Gather all combiner devices
    const maxCombinerData = Object.keys(maxPerCombiner).map((key) => {
      const label =
        selectData.find((sd) => sd.value === key)?.label || `Device ${key}`;
      const device = deviceMap.get(Number(key));
      const moduleId = device?.pv_module_id;
      const moduleName =
        pvModules.find((pv) => pv.pv_module_id === moduleId)?.model ||
        "Unknown Module";
      const performance = maxPerCombiner[key] ?? null;
      const maxDate = maxDatePerKey[key] || "";

      return {
        deviceId: key,
        performance,
        maxDate,
        label,
        moduleName,
        moduleId: moduleId ?? 0,
      };
    });

    // Group by module name
    const groupedByModule = groupBy(maxCombinerData, (item) => item.moduleName);

    // Build traces: each module is a separate trace (legend entry)
    return Object.entries(groupedByModule).map(([moduleName, items]) => ({
      x: items.map((i) => i.deviceId),
      y: items.map((i) => i.performance),
      type: "scatter" as const,
      mode: "markers" as const,
      name: moduleName, // legend entry
      marker: {
        color: items.map((i) => moduleIdToColorMap[i.moduleId]),
      },
      hovertemplate:
        "%{customdata[1]}<br>" +
        "Max Value: %{y:,.1%}<br>" +
        "Max Date: %{customdata[0]}<extra></extra>",
      customdata: items.map((i) => [i.maxDate, i.label]),
    }));
  }, [
    maxPerCombiner,
    selectData,
    deviceMap,
    maxDatePerKey,
    pvModules,
    moduleIdToColorMap,
  ]);

  return (
    <Stack>
      <CustomCard
        title="Project Mean per Day"
        style={{ height: "35vh" }}
        info={
          "This plot shows the average DC performance across all combiners per day. " +
          "The trendline slope is calculated by linear regression, then extrapolated to a 1-year period."
        }
      >
        <Stack h="100%">
          <Text>
            Trendline Slope: {(trendlineChangePerYear * 100).toFixed(1)}% per
            year
          </Text>
          <PlotlyPlot
            data={[
              {
                x: filteredData.x,
                y: averagePerDay,
                type: "scatter",
                mode: "lines+markers",
                name: "Average DC Performance",
              },
              {
                x: filteredData.x,
                y: trendline,
                type: "scatter",
                mode: "lines",
                name: "Trendline",
                line: {
                  dash: "dash",
                  color: computedColorScheme === "dark" ? "white" : "black",
                },
              },
            ]}
            layout={{
              xaxis: {
                title: "Date",
              },
              yaxis: {
                title: "DC Performance",
                tickformat: ",.1%",
              },
              legend: {
                x: 0,
                y: 1,
                bgcolor: "rgba(255, 255, 255, 0)",
                bordercolor: "rgba(0,0,0,0)",
              },
            }}
          />
        </Stack>
      </CustomCard>
      <Group h="70vh" grow>
        <Stack h="100%" justify="flex-start">
          <CustomCard
            title={`Avg. DC Performance by ${
              deviceTypes.find(
                (deviceType) => deviceType.value === selectedDeviceType
              )?.label
            }`}
            style={{ height: "50%" }}
            headerChildren={
              <Select
                data={deviceTypes.map((deviceType) => ({
                  value: deviceType.value,
                  label: deviceType.label,
                }))}
                onChange={(item) => setSelectedDeviceType(item)}
                value={selectedDeviceType}
                size="xs"
              />
            }
            info={`This plot shows the average DC performance across all days by ${
              deviceTypes.find((d) => d.value === selectedDeviceType)?.label
            }. The combiner device type is split by module (each is a separate legend entry).`}
          >
            {selectedDeviceType === "9" && (
              <PlotlyPlot
                data={combinerDataForAvg}
                layout={{
                  hovermode: "closest",
                  xaxis: {
                    showticklabels: false,
                    showgrid: false,
                    title: "Combiner",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.0%",
                  },
                }}
              />
            )}
            {selectedDeviceType === "2" && (
              <PlotlyPlot
                data={[
                  {
                    x: Object.keys(averagePerInverter),
                    y: Object.values(averagePerInverter),
                    type: "scatter",
                    mode: "markers",
                    name: "",
                    hovertemplate:
                      "%{customdata}<br>" + "Average Value: %{y:,.1%}<br>",
                    customdata: Object.keys(averagePerInverter).map((key) => {
                      const label =
                        selectData.find((sd) => sd.value === key)?.label || key;
                      return label;
                    }),
                  },
                ]}
                layout={{
                  hovermode: "closest",
                  xaxis: {
                    showticklabels: false,
                    showgrid: false,
                    title: "Inverter",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.0%",
                  },
                }}
              />
            )}
            {selectedDeviceType === "14" && (
              <PlotlyPlot
                data={[
                  {
                    x: Object.keys(averagePerCircuit),
                    y: Object.values(averagePerCircuit),
                    type: "scatter",
                    mode: "markers",
                    name: "",
                    hovertemplate:
                      "%{customdata}<br>" + "Average Value: %{y:,.1%}<br>",
                    customdata: Object.keys(averagePerCircuit).map((key) => {
                      const label =
                        selectData.find((sd) => sd.value === key)?.label || key;
                      return label;
                    }),
                  },
                ]}
                layout={{
                  hovermode: "closest",
                  xaxis: {
                    showticklabels: false,
                    showgrid: false,
                    title: "Circuit",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.0%",
                  },
                }}
              />
            )}
          </CustomCard>

          <CustomCard
            title={`Maximum DC Performance by ${
              deviceTypes.find(
                (deviceType) => deviceType.value === selectedDeviceType
              )?.label
            }`}
            style={{ height: "50%" }}
            info={`This plot shows the maximum DC performance across all days by ${
              deviceTypes.find((d) => d.value === selectedDeviceType)?.label
            }. For inverters/circuits, we take the daily mean of their combiners, then plot the maximum value of those daily means.`}
          >
            {selectedDeviceType === "9" && (
              <PlotlyPlot
                data={combinerDataForMax}
                layout={{
                  hovermode: "closest",
                  xaxis: {
                    showticklabels: false,
                    showgrid: false,
                    title: "Combiner",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.0%",
                  },
                }}
              />
            )}
            {selectedDeviceType === "2" && (
              <PlotlyPlot
                data={[
                  {
                    x: Object.keys(maxPerInverter),
                    y: Object.values(maxPerInverter),
                    type: "scatter",
                    mode: "markers",
                    name: "",
                    hovertemplate:
                      "%{customdata}<br>" + "Max Value: %{y:,.1%}<br>",
                    customdata: Object.keys(maxPerInverter).map((key) => {
                      const label =
                        selectData.find((sd) => sd.value === key)?.label || key;
                      return label;
                    }),
                  },
                ]}
                layout={{
                  hovermode: "closest",
                  xaxis: {
                    showticklabels: false,
                    showgrid: false,
                    title: "Inverter",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.0%",
                  },
                }}
              />
            )}
            {selectedDeviceType === "14" && (
              <PlotlyPlot
                data={[
                  {
                    x: Object.keys(maxPerCircuit),
                    y: Object.values(maxPerCircuit),
                    type: "scatter",
                    mode: "markers",
                    name: "",
                    hovertemplate:
                      "%{customdata}<br>" + "Max Value: %{y:,.1%}<br>",
                    customdata: Object.keys(maxPerCircuit).map((key) => {
                      const label =
                        selectData.find((sd) => sd.value === key)?.label || key;
                      return label;
                    }),
                  },
                ]}
                layout={{
                  hovermode: "closest",
                  xaxis: {
                    showticklabels: false,
                    showgrid: false,
                    title: "Circuit",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.0%",
                  },
                }}
              />
            )}
          </CustomCard>
        </Stack>
        <Stack h="100%" justify="flex-start">
          <CustomCard
            title="Histogram: DC Performance Bins"
            style={{ height: "50%" }}
            info={`This plot shows the distribution of DC performance across all days. The bins are defined by 1% increments
              (e.g. a value of 1,000 for the 98% bin means that 1,000 combiners had a DC performance between 98% and 98.9% when averaged across all days).`}
          >
            <PlotlyPlot
              data={[
                {
                  x: Object.keys(binValues).map(Number),
                  y: Object.values(binValues).map(Number),
                  type: "bar",
                },
              ]}
              layout={{
                bargap: 0,
                xaxis: {
                  tickformat: ",.0%",
                  tickangle: -45,
                  title: "DC Performance",
                  type: "linear",
                },
                yaxis: {
                  title: "Combiner Count",
                },
              }}
            />
          </CustomCard>

          <CustomCard
            title="Device Performance"
            style={{ height: "50%" }}
            headerChildren={
              <Select
                data={selectData}
                onChange={(item) => setSelectedKey(item)}
                searchable
                value={selectedKey}
                placeholder="Select Device"
                size="xs"
                clearable={false}
              />
            }
            info={`This plot shows the DC performance of the selected device over time. Select a device on the right to view its performance.`}
          >
            {selectedKey ? (
              <PlotlyPlot
                data={[
                  {
                    x: filteredData.x,
                    y: filteredData.y.map((item) => item[selectedKey || ""]),
                    type: "scatter",
                    mode: "markers",
                  },
                ]}
                layout={{
                  xaxis: {
                    title: "Date",
                  },
                  yaxis: {
                    title: "DC Performance",
                    tickformat: ",.1%",
                  },
                }}
              />
            ) : (
              <Stack justify="center" align="center" h="100%">
                <Text>Select a device to view its performance over time.</Text>
                <IconDatabasePlus size={48} />
              </Stack>
            )}
          </CustomCard>
        </Stack>
      </Group>
    </Stack>
  );
};

const MemoizedGraphsTab = memo(GraphsTab, (prevProps, nextProps) => {
  return (
    shallowEqual(prevProps.filteredData, nextProps.filteredData) &&
    shallowEqual(prevProps.devices, nextProps.devices) &&
    shallowEqual(prevProps.averagePerCombiner, nextProps.averagePerCombiner)
  );
});

const ModuleDegradation: React.FC = () => {
  // Variables and states
  const { projectId } = useParams();
  const [viewDate, setViewDate] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("graphs");
  const [excludedDates, setExcludedDates] = useState<string[]>([]);
  const { start, end } = useValidateDateRange({});

  // API calls
  const { data: project } = useGetProject({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: {
      enabled: !!projectId,
    },
  });
  const timezone = project?.time_zone || "America/Chicago";

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  if (project) {
    if (start) {
      startQuery = start.tz(project.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.time_zone, true).toISOString();
    }
  }

  const { data: moduleDegradation, isLoading: isModuleDegradationLoading } =
    useGetKPIData({
      pathParams: { projectId: projectId || "" },
      queryParams: {
        kpi_type_id: 17,
        start: startQuery,
        end: endQuery,
      },
      queryOptions: {
        enabled: !!projectId && !!startQuery && !!endQuery,
      },
    });

  const { data: viewDatePOA, isLoading: isViewDatePOALoading } =
    useGetClearskyPOA({
      pathParams: { projectId: projectId || "" },
      queryParams: {
        start: viewDate ? dayjs(viewDate).toISOString() : "",
        end: viewDate ? dayjs(viewDate).add(1, "day").toISOString() : "",
      },
      queryOptions: {
        enabled: !!viewDate,
      },
    });

  const { data: devices, isLoading: isDevicesLoading } = useGetDevices({
    pathParams: { projectId: projectId || "" },
    queryParams: {
      device_type_ids: [2, 6, 9, 14],
    },
    queryOptions: {
      enabled: !!projectId,
    },
  });

  const { data: pvModules, isLoading: isPvModulesLoading } = useGetPvModules({
    pathParams: { projectId: projectId || "" },
    queryOptions: {
      enabled: !!projectId,
    },
  });

  // Helper function for Clearsky POA
  function processPoaData(
    poaData: DataTimeSeries[],
    maxPOA1stDerivative: number,
    minPOA: number,
    maxPOA1stDerivativeStd: number,
    timezone: string,
    usePOA1d: boolean,
    usePOA1dStd: boolean
  ): { data: DataTimeSeries[]; layout: Partial<Layout>; validPoints: number } {
    let validPoints = 0;
    if (!poaData) return { data: [], layout: {}, validPoints };

    const poa1dTrace = poaData.find((trace) => trace.name === "POA 1D");
    const poa1dStdTrace = poaData.find(
      (trace) => trace.name === "POA 1D Std Dev"
    );

    if (!poa1dTrace || !poa1dStdTrace) {
      return { data: poaData, layout: {}, validPoints };
    }

    const otherTraces = poaData.filter(
      (trace) => !["POA 1D", "POA 1D Std Dev"].includes(trace.name)
    );

    const shapes: Partial<Shape>[] = [];
    let currentShape: Partial<Shape> | null = null;

    poa1dTrace.x.forEach((x: string | number, i: number) => {
      const y1d = poa1dTrace.y[i];
      const y1dStd = poa1dStdTrace.y[i];
      const meanOther =
        otherTraces.reduce((sum, trace) => sum + (trace.y[i] || 0), 0) /
        otherTraces.length;

      const isValidPoint =
        (usePOA1d ? y1d != null : true) &&
        (usePOA1dStd ? y1dStd != null : true) &&
        (usePOA1d ? Math.abs(y1d) < maxPOA1stDerivative : true) &&
        (usePOA1dStd ? y1dStd < maxPOA1stDerivativeStd : true) &&
        meanOther > minPOA;

      if (isValidPoint) {
        validPoints++;
        if (!currentShape) {
          currentShape = {
            type: "rect",
            xref: "x",
            yref: "paper",
            x0: x,
            y0: 0,
            x1: x,
            y1: 1,
            fillcolor: "rgba(0, 255, 0, 0.2)",
            line: { width: 0 },
          };
        } else {
          currentShape.x1 = x;
        }
      } else if (currentShape) {
        shapes.push(currentShape);
        currentShape = null;
      }
    });

    if (currentShape) {
      shapes.push(currentShape);
    }

    shapes.forEach((shape) => {
      if (shape.x0 === shape.x1) {
        const hourOffset = dayjs(shape.x0).tz(timezone).utcOffset() / 60;
        const x0 = dayjs(shape.x0)
          .tz(timezone)
          .subtract(2, "minute")
          .add(hourOffset, "hour")
          .toISOString();
        const x1 = dayjs(shape.x1)
          .tz(timezone)
          .add(2, "minute")
          .add(hourOffset, "hour")
          .toISOString();
        shape.x0 = x0;
        shape.x1 = x1;
      }
    });

    const layout: Partial<Layout> = {
      shapes,
      showlegend: true,
      yaxis: {
        title: "POA",
      },
      yaxis2: {
        title: "Filters",
        showgrid: false,
        zeroline: false,
        side: "right",
        overlaying: "y",
      },
    };

    return { data: poaData, layout, validPoints };
  }

  const { data: plotData, layout } = useMemo(() => {
    return processPoaData(
      viewDatePOA || [],
      1,
      200,
      1,
      timezone || "",
      true,
      true
    );
  }, [viewDatePOA, timezone]);

  // Filtered degradation data
  const dates = moduleDegradation?.x;

  const filteredData = useMemo(
    () => ({
      x:
        moduleDegradation?.x.filter((date) => !excludedDates.includes(date)) ||
        [],
      y:
        moduleDegradation?.y
          .filter(
            (_, index) => !excludedDates.includes(moduleDegradation.x[index])
          )
          .map((item) => item.device_values) || [],
    }),
    [excludedDates, moduleDegradation]
  );

  const filteredIndex: number =
    moduleDegradation?.x.indexOf(
      moduleDegradation?.x.find((date) => date === viewDate) || ""
    ) || 0;

  // Compute averagePerCombiner from filtered data
  const averagePerCombiner: { [deviceId: string]: number | null } =
    useMemo(() => {
      const averagePerCombiner: { [deviceId: string]: number | null } = {};
      if (filteredData.y) {
        const accumulation = filteredData.y.reduce((acc, item) => {
          Object.entries(item).forEach(([key, value]) => {
            if (value != null) {
              if (!acc[key]) {
                acc[key] = { sum: 0, count: 0 };
              }
              acc[key].sum += value;
              acc[key].count += 1;
            }
          });
          return acc;
        }, {} as { [deviceId: string]: { sum: number; count: number } });

        for (const key in accumulation) {
          averagePerCombiner[key] =
            accumulation[key].count > 0
              ? accumulation[key].sum / accumulation[key].count
              : null;
        }
      }
      return averagePerCombiner;
    }, [filteredData.y]);

  // For Clearsky "Combiner Performance" chart
  const selectDataClearsky = useMemo(() => {
    return Object.keys(averagePerCombiner)
      .filter((key) =>
        devices?.some((device) => device.device_id === Number(key))
      )
      .map((key) => {
        const device = devices?.find(
          (device) => device.device_id === Number(key)
        );
        return {
          value: key,
          label: device?.name_full || `Device ${key}`,
        };
      });
  }, [averagePerCombiner, devices]);

  if (isModuleDegradationLoading || isDevicesLoading || isPvModulesLoading) {
    return <PageLoader />;
  }

  return (
    <Stack w="100%" h="100%" p="md" gap="sm">
      <Group justify="space-between" align="flex-start">
        <Group>
          <Title>Module Degradation</Title>
          <AdvancedDatePicker defaultRange={"past-year"} />
        </Group>
        <ActionIcon size="xl">
          <IconFileTypeCsv
            onClick={() => exportToCsv(filteredData, devices || [])}
          />
        </ActionIcon>
      </Group>
      <Group>
        <Text size="sm" w="75%" style={{ textAlign: "justify" }}>
          This report is designed to characterize the performance of the modules
          at the most granular level possible. The analysis is generated by
          heavily filtering data for clearsky, high-performance timestamps which
          guarantee that shortfalls can be attributed to module degradation or
          other DC performance issues. Note that this report assumes Proximal
          has the most updated knowledge of SCADA tag association with combiners
          and correct BIN classes. Combiner mismatch or incorrect BIN class
          issues can lead to severe inaccuracies in the DC Performance report.
        </Text>
      </Group>
      <Tabs
        value={activeTab}
        onChange={(value: string | null) => setActiveTab(value ?? "graphs")}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="graphs">Graphs</Tabs.Tab>
          <Tabs.Tab value="gis">GIS</Tabs.Tab>
          <Tabs.Tab value="clearsky">
            <Group align="center" gap={2}>
              Clearsky
              <HoverCard>
                <HoverCard.Target>
                  <IconInfoCircle size={16} />
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">
                    Use this tab to view individual days' clearsky and combiner
                    performance data. Entire days can be excluded from the
                    analysis as applicable.{" "}
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          value="clearsky"
          pt="md"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Group h="100%" w="100%" justify="center">
            <Stack h="100%" flex={1}>
              <Paper withBorder h="100%" p="xs">
                <ScrollArea h="100%">
                  <Stack h="100%" align="center">
                    <Text>Included Dates</Text>
                    {dates
                      ?.filter((date) => !excludedDates.includes(date))
                      .map((date) => (
                        <Button
                          key={date}
                          size="xs"
                          onClick={() => {
                            setViewDate(date);
                          }}
                          variant={viewDate === date ? "filled" : "outline"}
                          fullWidth
                        >
                          {dayjs(date).tz(timezone).format("MM/DD/YYYY")}
                        </Button>
                      ))}
                  </Stack>
                </ScrollArea>
              </Paper>
            </Stack>
            <Stack h="100%" flex={8}>
              <CustomCard
                title={`Combiner Performance${
                  viewDate
                    ? ": " + dayjs(viewDate).tz(timezone).format("MM/DD/YYYY")
                    : ""
                }`}
                style={{ width: "100%", height: "50%" }}
              >
                {moduleDegradation?.y[filteredIndex ?? 0] ? (
                  <PlotlyPlot
                    data={[
                      {
                        x: moduleDegradation?.y[filteredIndex ?? 0]
                          ? Object.keys(
                              moduleDegradation?.y[filteredIndex ?? 0]
                                .device_values ?? []
                            )
                          : [],
                        y: moduleDegradation?.y[filteredIndex ?? 0]
                          ? Object.values(
                              moduleDegradation?.y[filteredIndex ?? 0]
                                .device_values ?? []
                            )
                          : [],
                        type: "scatter",
                        mode: "markers",
                        hovertemplate:
                          "%{customdata}<br>" + "DC Performance: %{y:,.1%}<br>",
                        customdata: Object.keys(
                          moduleDegradation?.y[filteredIndex ?? 0]
                            .device_values ?? []
                        ).map((key) => {
                          const label =
                            selectDataClearsky.find((sd) => sd.value === key)
                              ?.label || key;
                          return label;
                        }),
                        name: "",
                      },
                    ]}
                    layout={{
                      hovermode: "closest",
                      xaxis: {
                        showticklabels: false,
                        showgrid: false,
                        title: "Combiner",
                      },
                      yaxis: {
                        title: "DC Performance",
                        tickformat: ",.0%",
                      },
                    }}
                  />
                ) : (
                  <Stack justify="center" align="center" h="100%">
                    <Text>
                      Select a date to view combiner performance & clearsky
                      data.
                    </Text>
                    <IconDatabasePlus size={48} />
                  </Stack>
                )}
              </CustomCard>
              <CustomCard
                style={{ width: "100%", height: "50%" }}
                title="Clearsky POA"
              >
                {moduleDegradation?.y[filteredIndex ?? 0] ? (
                  <PlotlyPlot
                    data={plotData}
                    layout={layout}
                    isLoading={isViewDatePOALoading}
                  />
                ) : (
                  <Stack justify="center" align="center" h="100%">
                    <IconDatabasePlus size={48} />
                  </Stack>
                )}
              </CustomCard>

              <Button
                onClick={() =>
                  setExcludedDates([...excludedDates, viewDate || ""])
                }
              >
                Exclude
              </Button>
            </Stack>
            <Paper withBorder h="100%" p="xs" flex={1}>
              <ScrollArea h="100%">
                <Stack h="100%" align="center">
                  <Text>Excluded Dates</Text>
                  {excludedDates.map((date) => (
                    <Button
                      key={date}
                      size="xs"
                      onClick={() =>
                        setExcludedDates(
                          excludedDates.filter((d) => d !== date)
                        )
                      }
                      fullWidth
                    >
                      {dayjs(date).tz(timezone).format("MM/DD/YYYY")}
                    </Button>
                  ))}
                </Stack>
              </ScrollArea>
            </Paper>
          </Group>
        </Tabs.Panel>
        <Tabs.Panel
          value="graphs"
          pt="md"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <ScrollArea>
            {activeTab === "graphs" && startQuery && (
              <MemoizedGraphsTab
                filteredData={filteredData}
                devices={devices}
                averagePerCombiner={averagePerCombiner}
                project={project || ({} as Project)}
                pvModules={pvModules || []}
              />
            )}
          </ScrollArea>
        </Tabs.Panel>
        <Tabs.Panel value="gis" h="100%" w="100%" pt="md">
          {activeTab === "gis" && (
            <GisTab averagePerCombiner={averagePerCombiner} devices={devices} />
          )}
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default ModuleDegradation;
