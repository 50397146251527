import * as types from "@/hooks/types";
import { Box, Group, ScrollArea, Text, Timeline } from "@mantine/core";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const DeviceEventsTimeline = ({
  events,
  rootCauses,
  projectId,
  selectedEvent,
}: {
  events: types.Event[];
  rootCauses: types.RootCause[];
  projectId: string;
  selectedEvent: types.Event;
}) => {
  events = [...events].sort(
    (a, b) => dayjs(b.time_start).unix() - dayjs(a.time_start).unix(),
  );

  let timelineLength = 2;
  if (events[0].time_end) {
    timelineLength = 1;
  }

  return (
    <ScrollArea scrollbars="y" p="xs">
      <ScrollArea.Autosize mah={650} mih={650}>
        <Box>
          <Timeline active={events.length - timelineLength} reverseActive>
            {events.map((event, index) => (
              <Timeline.Item key={index}>
                <Link
                  to={`/projects/${projectId}/events/event/?eventId=${event.event_id}`}
                  style={{ color: "inherit" }}
                >
                  <Text
                    style={{
                      fontWeight:
                        selectedEvent.event_id === event.event_id
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {event.time_end
                      ? `${dayjs(event.time_start).format(
                          "MM/DD/YYYY",
                        )} - ${dayjs(event.time_end).format("MM/DD/YYYY")}`
                      : dayjs(event.time_start).format("MM/DD/YYYY")}
                  </Text>
                </Link>
                <Group>
                  <Text
                    size="sm"
                    style={{
                      fontWeight:
                        selectedEvent.event_id === event.event_id
                          ? "bold"
                          : "normal",
                    }}
                  >
                    Root cause:{" "}
                    {rootCauses.find(
                      (fm) => fm.root_cause_id === event.root_cause_id,
                    )?.name_long || "Unknown"}
                  </Text>
                </Group>
              </Timeline.Item>
            ))}
          </Timeline>
        </Box>
      </ScrollArea.Autosize>
    </ScrollArea>
  );
};

export default DeviceEventsTimeline;
