import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useGetBlockDropdown } from "@/api/ui";
import BlockDropdown from "@/components/BlockDropdown";
import CustomCard from "@/components/CustomCard";
import { AdvancedDatePicker } from "@/components/GIS";
import { useValidateDateRange } from "@/components/datepicker/utils";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetDevices,
  useGetProject,
  useGetTags,
  useGetTrackerEquipmentAnalysis,
} from "@/hooks/api";
import {
  ActionIcon,
  Group,
  Popover,
  SegmentedControl,
  Stack,
  Title,
} from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { Data } from "plotly.js";
import { useState } from "react";

const MAX_DAYS = 7;

const ProjectEquipmentAnalysisTracker = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [positionView, setPositionView] = useState<"block" | "row">("block");
  const [setpointView, setSetpointView] = useState<"block" | "row">("block");

  const [searchParams] = useSearchParams();
  const startURI = searchParams.get("start");
  const endURI = searchParams.get("end");

  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { device_type_ids: [6, 10, 2] },
  });

  const blockDropdown = useGetBlockDropdown({
    pathParams: { projectId: projectId || "-1" },
  });

  const handleBlockDropdownChange = (value: string | null) => {
    if (value) {
      searchParams.set("deviceId", value);
      navigate(
        `/projects/${projectId}/equipment-analysis/tracker/block?${searchParams.toString()}`,
      );
    }
  };

  const deviceMap =
    devices.data
      ?.filter((device) => device.device_type_id === 6)
      .reduce(
        (acc, device) => {
          acc[`Block ${device.name_long}`] = device.device_id;
          return acc;
        },
        {} as Record<string, number | string>,
      ) || {};

  const tagDevices = devices.data;
  const findParentDeviceId = (
    deviceId: number | string,
  ): number | string | null => {
    const device = tagDevices?.find((d) => d.device_id === deviceId);
    if (!device) return null;
    if (device.device_type_id === 6) return device.device_id;
    return findParentDeviceId(device.parent_device_id ?? "");
  };

  const tags = useGetTags({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { sensor_type_ids: [24] },
  });

  const tagMap =
    tags.data?.reduce(
      (acc, tag) => {
        const associatedDeviceId = findParentDeviceId(tag.device_id ?? "");
        if (associatedDeviceId) {
          acc[`${tag.name_long}`] = associatedDeviceId;
        }
        return acc;
      },
      {} as Record<string, number | string>,
    ) || {};

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { start, end } = useValidateDateRange({
    maxDays: MAX_DAYS,
  });

  let startRequest, endRequest;
  if (project.data) {
    startRequest =
      start && start.tz(project.data.time_zone, true).toISOString();
    endRequest =
      end &&
      end.tz(project.data.time_zone, true).subtract(1, "days").toISOString();
  }

  const { data, isLoading } = useGetTrackerEquipmentAnalysis({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      start: startRequest ?? undefined,
      end: endRequest ?? undefined,
    },
  });

  const positionData = data?.position_from_setpoint;
  const setpointData = data?.setpoint_from_median;

  const PositionData = () => {
    return positionView === "block"
      ? [
          {
            x: Object.keys(positionData?.by_block || {}).map((key) => key), // Extract block identifiers as strings
            y: Object.values(positionData?.by_block || {}), // Extract corresponding values
            type: "bar",
          },
        ]
      : Object.entries(positionData?.by_row || {}).map(([blockId, values]) => ({
          x: Object.keys(values), // Extract row keys as strings
          y: Object.values(values), // Extract corresponding values
          type: "bar", // Specify the type as bar
          name: `${blockId}`, // Optional: Name for the legend
        }));
  };

  const SetpointData = () => {
    return setpointView === "block"
      ? [
          {
            x: Object.keys(setpointData?.by_block || {}).map((key) => key), // Extract block identifiers as strings
            y: Object.values(setpointData?.by_block || {}), // Extract corresponding values
            type: "bar",
          },
        ]
      : Object.entries(setpointData?.by_row || {}).map(([blockId, values]) => ({
          x: Object.keys(values), // Extract row keys as strings
          y: Object.values(values), // Extract corresponding values
          type: "bar", // Specify the type as bar
          name: `${blockId}`, // Optional: Name for the legend
        }));
  };

  return (
    <Stack p="md">
      <Title order={1}>Tracker Equipment Analysis</Title>
      <Group>
        <BlockDropdown
          data={blockDropdown.data}
          value={null}
          onChange={handleBlockDropdownChange}
          includeNextPrevious={false}
          includeFirstLast={false}
        />
        <AdvancedDatePicker
          includeClearButton={false}
          defaultRange="today"
          limits={{
            day: 7,
            week: 1,
            month: 0,
            quarter: 0,
            year: 0,
          }}
          disableQuickActions={true}
          maxDays={MAX_DAYS}
        />
      </Group>
      <CustomCard
        title="Tracker Position Deviating From Setpoint"
        headerChildren={
          <ActionIcon variant="default">
            <Popover>
              <Popover.Target>
                <IconSettings />
              </Popover.Target>
              <Popover.Dropdown>
                <SegmentedControl
                  value={positionView}
                  onChange={(value) =>
                    setPositionView(value as "block" | "row")
                  }
                  data={[
                    { label: "By Block", value: "block" },
                    { label: "By Row", value: "row" },
                  ]}
                />
              </Popover.Dropdown>
            </Popover>
          </ActionIcon>
        }
        style={{ height: "50vh" }}
      >
        <PlotlyPlot
          isLoading={isLoading}
          data={PositionData() as Data[]}
          layout={{
            xaxis: {
              title: positionView === "block" ? "Block" : "Row", // Change label based on view
            },
            yaxis: {
              title: "Degrees",
            },
          }}
          onClick={(event) => {
            const xValue = event.points[0]?.x;
            if (xValue) {
              const deviceId =
                positionView === "row"
                  ? tagMap[xValue as string] // Use tagMap if positionView is "row"
                  : deviceMap[xValue as string]; // Use deviceMap otherwise
              navigate(
                `/projects/${projectId}/equipment-analysis/tracker/block?deviceId=${deviceId}&start=${startURI}&end=${endURI}`,
              );
            }
          }}
        />
      </CustomCard>
      <CustomCard
        title="Tracker Setpoint Deviating From Median"
        headerChildren={
          <ActionIcon variant="default">
            <Popover>
              <Popover.Target>
                <IconSettings />
              </Popover.Target>
              <Popover.Dropdown>
                <SegmentedControl
                  value={setpointView}
                  onChange={(value) =>
                    setSetpointView(value as "block" | "row")
                  }
                  data={[
                    { label: "By Block", value: "block" },
                    { label: "By Row", value: "row" },
                  ]}
                />
              </Popover.Dropdown>
            </Popover>
          </ActionIcon>
        }
        style={{ height: "50vh" }}
      >
        <PlotlyPlot
          isLoading={isLoading}
          data={SetpointData() as Data[]}
          layout={{
            xaxis: {
              title: setpointView === "block" ? "Block" : "Row", // Change label based on view
            },
            yaxis: {
              title: "Degrees",
            },
          }}
          onClick={(event) => {
            const xValue = event.points[0]?.x;
            if (xValue) {
              const deviceId =
                setpointView === "row"
                  ? tagMap[xValue as string] // Use tagMap if positionView is "row"
                  : deviceMap[xValue as string]; // Use deviceMap otherwise
              navigate(
                `/projects/${projectId}/equipment-analysis/tracker/block?deviceId=${deviceId}&start=${startURI}&end=${endURI}`,
              );
            }
          }}
        />
      </CustomCard>
    </Stack>
  );
};

export default ProjectEquipmentAnalysisTracker;
