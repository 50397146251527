import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetBESSBlockCycleCountKPIData,
  useGetDevices,
  useGetPCSMechanicalAvailabilityKPIData,
  useGetProject,
} from "@/hooks/api";
import { DatetimeDataFrame, Device } from "@/hooks/types";
import {
  Divider,
  SegmentedControl,
  Stack,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { PlotData } from "plotly.js";
import { useState } from "react";
import { useParams } from "react-router-dom";

const PERCENT_DEVIATION_THRESHOLD = 0.05;

const Page = () => {
  const { projectId } = useParams();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { start, end } = useValidateDateRange({});

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  if (project.data) {
    if (start) {
      startQuery = start.tz(project.data.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.data.time_zone, true).toISOString();
    }
  }

  const devices = useGetDevices({
    pathParams: {
      projectId: projectId || "-1",
    },
  });

  const data = useGetBESSBlockCycleCountKPIData({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      start: startQuery,
      end: endQuery,
    },
    queryOptions: {
      enabled: !!project.data && !!start && !!end,
    },
  });

  return (
    <Stack p="md" h="100%">
      <Title order={1}>BESS Block Cycle Count</Title>
      <AdvancedDatePicker includeClearButton={false} defaultRange="past-week" />
      <PlotCard devices={devices} data={data} />
    </Stack>
  );
};

const PlotCard = ({
  devices,
  data,
}: {
  devices: ReturnType<typeof useGetDevices>;
  data: ReturnType<typeof useGetPCSMechanicalAvailabilityKPIData>;
}) => {
  const [plotType, setPlotType] = useState("bar");
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme("dark");
  const parseData = (devices: Device[], data: DatetimeDataFrame) => {
    // Create an object mapping device.device_id to device.name_long
    const deviceNameMap: { [key: string]: string | null } = {};
    devices.forEach((device) => {
      deviceNameMap[device.device_id] = device.name_long;
    });

    const columnNames = data.columns.map((column) => deviceNameMap[column]);

    if (plotType === "bar") {
      const x = columnNames;
      const y = data.data[0]
        .map((_, colIndex) => data.data.map((row) => row[colIndex]))
        .map((row) => row.reduce((acc, val) => acc + val, 0));

      // Calculate average and deviation
      const averageY = y.reduce((acc, val) => acc + val, 0) / y.length;
      const deviation = y.map((val) => Math.abs(val - averageY) / averageY);

      // Combine color conditions
      const colors = y.map((val, index) => {
        if (val > data.data.length) {
          return theme.colors.red[7];
        } else if (deviation[index] > PERCENT_DEVIATION_THRESHOLD) {
          return theme.colors.yellow[7];
        } else {
          return computedColorScheme === "dark"
            ? theme.colors.dark[0]
            : "black";
        }
      });

      return {
        x: x,
        y: y,
        type: "bar",
        marker: {
          color: colors,
        },
      } as PlotData;
    } else {
      const x = data.index;
      const y = columnNames;

      const z = data.data[0].map((_, colIndex) =>
        data.data.map((row) => row[colIndex]),
      );

      return {
        x: x,
        y: y,
        z: z,
        type: "heatmap",
      } as PlotData;
    }
  };

  const parsedData =
    devices.data && data.data && parseData(devices.data, data.data);

  const averageY =
    data.data &&
    data.data.data[0]
      .map((_, colIndex) => data.data.data.map((row) => row[colIndex]))
      .map((row) => row.reduce((acc, val) => acc + val, 0))
      .reduce((acc, val) => acc + val, 0) / data.data.data[0].length;

  const thresholdY = data.data && data.data.index.length;

  return (
    <BarAndHeatmapCard
      parsedData={parsedData}
      averageY={averageY}
      thresholdY={thresholdY}
      isLoading={data.isLoading}
      plotType={plotType}
      setPlotType={setPlotType}
    />
  );
};

const BarAndHeatmapCard = ({
  parsedData,
  averageY,
  thresholdY,
  isLoading,
  plotType,
  setPlotType,
}: {
  parsedData: PlotData | undefined;
  averageY: number | undefined;
  thresholdY: number | undefined;
  isLoading: boolean;
  plotType: string;
  setPlotType: (value: string) => void;
}) => {
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme("dark");

  return (
    <CustomCard
      title="Cycles"
      info={`Blocks with a cycle count greater than the Contract Operating Restriction are red. Blocks with a cycle count deviating from the average by more than ${
        PERCENT_DEVIATION_THRESHOLD * 100
      }% are yellow.`}
      style={{ flex: 1 }}
      headerChildren={
        <>
          <SegmentedControl
            size="xs"
            value={plotType}
            onChange={setPlotType}
            data={[
              { label: "Bar", value: "bar" },
              { label: "Heatmap", value: "heatmap" },
            ]}
          />
          <Divider orientation="vertical" />
        </>
      }
    >
      <PlotlyPlot
        data={parsedData && [{ ...parsedData }]}
        layout={
          parsedData && plotType === "bar"
            ? {
                shapes: [
                  {
                    type: "line",
                    xref: "paper",
                    x0: 0,
                    y0: averageY,
                    x1: 1,
                    y1: averageY,
                    line: {
                      color:
                        computedColorScheme === "dark"
                          ? theme.colors.dark[0]
                          : "black",
                      width: 4,
                      dash: "dot",
                    },
                    label: {
                      text: `Average: ${averageY?.toFixed(1)}`,
                      yanchor: "top",
                      textposition: "start",
                      font: {
                        weight: 700,
                        shadow: "auto",
                      },
                    },
                  },
                  {
                    type: "line",
                    xref: "paper",
                    x0: 0,
                    y0: thresholdY,
                    x1: 1,
                    y1: thresholdY,
                    line: {
                      color: theme.colors.red[7],
                      width: 4,
                      dash: "dot",
                    },
                    label: {
                      text: `Contract Operating Restriction: ${thresholdY}`,
                      yanchor: "top",
                      textposition: "start",
                      font: {
                        weight: 700,
                        shadow: "auto",
                      },
                    },
                  },
                ],
                xaxis: {
                  type: "category",
                },
                yaxis: {
                  rangemode: "tozero",
                },
              }
            : {}
        }
        colorscale="primary"
        isLoading={isLoading}
      />
    </CustomCard>
  );
};

export default Page;
