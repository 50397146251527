import CustomCard from "@/components/CustomCard";
import { AdvancedDatePicker, ColorBar, MapSettings } from "@/components/GIS";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { GISContext } from "@/contexts/GISContext";
import {
  useGetDevices,
  useGetPCSMechanicalAvailabilityKPIData,
  useGetProject,
} from "@/hooks/api";
import { DatetimeDataFrame, Device } from "@/hooks/types";
import { dfMean, downloadCSV } from "@/utils";
import * as gisUtils from "@/utils/GIS";
import { findBoundingBox } from "@/utils/GIS";
import {
  Box,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import dayjs from "dayjs";
import { FeatureCollection } from "geojson";
import { Data } from "plotly.js";
import React, { useCallback, useContext, useState } from "react";
import {
  Layer,
  Map,
  MapLayerMouseEvent,
  MapboxGeoJSONFeature,
  Source,
} from "react-map-gl";
import { useParams, useSearchParams } from "react-router-dom";

type HoverInfo = {
  feature: MapboxGeoJSONFeature | null;
  x: number;
  y: number;
};

const BarAndHeatmapCard = ({
  parsedData,
  isLoading,
  plotType,
  setPlotType,
}: {
  parsedData: Data | undefined;
  isLoading: boolean;
  plotType: string;
  setPlotType: (value: string) => void;
}) => {
  return (
    <>
      <CustomCard
        title="Mechanical Availability"
        style={{ height: "50vh" }}
        headerChildren={
          <>
            <SegmentedControl
              size="xs"
              value={plotType}
              onChange={setPlotType}
              data={[
                { label: "Bar", value: "bar" },
                { label: "Heatmap", value: "heatmap" },
              ]}
            />
            <Divider orientation="vertical" />
          </>
        }
      >
        <PlotlyPlot
          data={parsedData && [{ ...parsedData }]}
          layout={
            plotType === "bar"
              ? {
                  xaxis: {
                    type: "category",
                  },
                  yaxis: {
                    range: [0, 1],
                    tickformat: ",.0%",
                  },
                }
              : {}
          }
          config={{ displayModeBar: false }}
          colorscale="primary"
          isLoading={isLoading}
        />
      </CustomCard>
    </>
  );
};

const PlotCard = ({
  devices,
  data,
  project,
}: {
  devices: ReturnType<typeof useGetDevices>;
  data: ReturnType<typeof useGetPCSMechanicalAvailabilityKPIData>;
  project: ReturnType<typeof useGetProject>;
}) => {
  const computedColorScheme = useComputedColorScheme("dark");

  const context = useContext(GISContext);
  const [plotType, setPlotType] = useState("bar");

  const [hoverInfo, setHoverInfo] = useState<HoverInfo>({
    feature: null,
    x: 0,
    y: 0,
  });

  const parseDevices = (devices: Device[], data: DatetimeDataFrame) => {
    const dataSum = dfMean(data);

    return {
      type: "FeatureCollection",
      features: devices.map((device) => ({
        type: "Feature",
        properties: {
          device_id: device.device_id,
          device_name: device.name_long,
          availability: dataSum[device.device_id] * 100 || 0,
        },
        geometry: device.polygon,
      })),
    } as FeatureCollection;
  };

  const parseData = (devices: Device[], data: DatetimeDataFrame) => {
    // Create an object mapping device.device_id to device.name_long
    const deviceNameMap: { [key: string]: string | null } = {};
    devices.forEach((device) => {
      deviceNameMap[device.device_id] = device.name_long;
    });

    const columnNames = data.columns.map((column) => deviceNameMap[column]);

    if (plotType === "bar") {
      const x = columnNames;
      let y = data.data[0]
        .map((_, colIndex) => data.data.map((row) => row[colIndex]))
        .map((row) => row.reduce((acc, val) => acc + val, 0));
      y = y.map((val) => val / data.data.length);

      return {
        x: x,
        y: y,
        type: "bar",
        hovertemplate: "%{y:.2%}<extra></extra>",
      } as Data;
    } else {
      const x = data.index;
      const y = columnNames;

      const z = data.data[0].map((_, colIndex) =>
        data.data.map((row) => row[colIndex]),
      );

      return {
        x: x,
        y: y,
        z: z,
        type: "heatmap",
        hovertemplate: "%{x}<br>%{y}<br>%{z:.2%}<extra></extra>",
        colorbar: {
          tickformat: ",.0%",
        },
      } as Data;
    }
  };

  const onHover = useCallback((event: MapLayerMouseEvent) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];

    if (hoveredFeature) {
      setHoverInfo({
        feature: hoveredFeature,
        x,
        y,
      });
    } else {
      setHoverInfo({
        feature: null,
        x: 0,
        y: 0,
      });
    }
  }, []);

  const parsedDevices =
    devices.data && data.data && parseDevices(devices.data, data.data);

  const parsedData =
    devices.data && data.data && parseData(devices.data, data.data);

  if (!context) {
    throw new Error("GISContext is not provided");
  }

  const mapStyleEmpty = !project.data?.has_pv_pcs_layout;
  const blankMapStyle = gisUtils.useBlankMapStyle();
  const { showLabels, showSatellite, colorsGoodBad } = context;

  const lowLabel = "0%";
  const highLabel = "100%";

  return (
    <>
      <BarAndHeatmapCard
        parsedData={parsedData}
        isLoading={data.isLoading}
        plotType={plotType}
        setPlotType={setPlotType}
      />
      <CustomCard title="Map" fill style={{ height: "50vh" }}>
        {parsedDevices && project.data ? (
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            <Map
              key={project.data.project_id}
              initialViewState={{
                bounds: findBoundingBox(parsedDevices),
                fitBoundsOptions: {
                  padding: 50,
                },
              }}
              style={{
                borderBottomLeftRadius: "inherit",
                borderBottomRightRadius: "inherit",
              }}
              interactiveLayerIds={["data"]}
              onMouseMove={onHover}
              mapStyle={
                gisUtils.mapStyle({
                  empty: mapStyleEmpty,
                  satellite: showSatellite,
                  theme: computedColorScheme,
                }) ?? blankMapStyle
              }
              mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
            >
              <Source type="geojson" data={parsedDevices}>
                <Layer
                  {...gisUtils.layerData({
                    featureKey: "availability",
                    colors: colorsGoodBad,
                    lowValue: 0,
                    highValue: 100,
                  })}
                />
                {showLabels && (
                  <Layer
                    {...gisUtils.layerLabel({ textField: "device_name" })}
                  />
                )}
              </Source>
              {hoverInfo.feature && (
                <Paper
                  p="xs"
                  style={{
                    left: hoverInfo.x,
                    top: hoverInfo.y,
                    position: "absolute",
                    zIndex: 9,
                    pointerEvents: "none",
                  }}
                >
                  <Text fw={700}>
                    {hoverInfo.feature.properties?.device_name}
                  </Text>
                  <Text>
                    Mechanical Availability:{" "}
                    {hoverInfo.feature.properties?.availability.toFixed(2)}%
                  </Text>
                </Paper>
              )}
            </Map>
            <Box
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
                height: "100%",
              }}
              px="md"
              py={75}
            >
              <ColorBar
                gradient={gisUtils.colorBar({ colors: colorsGoodBad })}
                lowLabel={lowLabel}
                highLabel={highLabel}
              />
            </Box>
            <Box
              style={{ position: "absolute", bottom: 0, left: 0, zIndex: 10 }}
              px="md"
              py="xl"
            >
              <MapSettings disableSatellite={mapStyleEmpty} />
            </Box>
          </div>
        ) : (
          <LoadingOverlay visible />
        )}
      </CustomCard>
    </>
  );
};

const ProjectKPIPCSEnergyProduction: React.FC = () => {
  const theme = useMantineTheme();

  const { projectId } = useParams();
  const [searchParams] = useSearchParams();

  // Parse query params
  let start: string | null = null;
  let end: string | null = null;

  if (searchParams.get("start")) {
    start = dayjs(searchParams.get("start")).toISOString();
  }
  if (searchParams.get("end")) {
    end = dayjs(searchParams.get("end")).add(1, "day").toISOString();
  }

  // Query Project data
  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  // Query devices
  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      device_type_ids: [2],
    },
  });

  // Query KPI data
  const data = useGetPCSMechanicalAvailabilityKPIData({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      start: start || "",
      end: end || "",
    },
    queryOptions: {
      enabled: !!start && !!start,
      staleTime: Infinity,
    },
  });

  const download = () => {
    if (
      data.data == undefined ||
      devices.data == undefined ||
      project.data == undefined
    ) {
      return;
    }

    // Map each column value (string) to the name_long from devices
    const deviceNameMap: { [key: string]: string | null } = {};
    devices.data?.forEach((device) => {
      deviceNameMap[device.device_id] = device.name_long;
    });

    // Create the headers
    const headers = ["Date"].concat(
      data.data.columns.map((column) => deviceNameMap[column] || column),
    );

    const dataValues = data.data.data;
    const indexValues = data.data.index;
    const filename = `${
      project.data.name_long
    } - PV PCS Mechanical Availability - ${dayjs(
      searchParams.get("start"),
    ).format("YYYY-MM-DD")}-${dayjs(searchParams.get("end")).format(
      "YYYY-MM-DD",
    )}.csv`;

    // Convert the data to CSV format
    const rows = [
      headers.join(","),
      ...dataValues.map((row, i) => [indexValues[i], ...row].join(",")),
    ];

    // Add a first row that says when the report was generated
    rows.unshift("");
    rows.unshift(`Report generated at ${dayjs().toISOString()}`);

    const csv = rows.join("\n");

    downloadCSV(csv, filename);
  };

  return (
    <Stack p="md">
      <Title order={1}>PV PCS Mechanical Availability</Title>
      <Group>
        <AdvancedDatePicker
          includeClearButton={false}
          defaultRange="past-week"
        />
        <Button
          disabled={data.data === undefined}
          variant="gradient"
          onClick={download}
          rightSection={<IconDownload size={14} />}
          gradient={{ from: "gray", to: theme.primaryColor, deg: 180 }}
        >
          Download
        </Button>
      </Group>
      <PlotCard devices={devices} data={data} project={project} />
    </Stack>
  );
};

export default ProjectKPIPCSEnergyProduction;
