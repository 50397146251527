import {
  useGetCompanyPermissions,
  useGetCompanyUsersPermissions,
  useUpdateUserPermissionMutation,
} from "@/api/admin";
import CustomCard from "@/components/CustomCard";
import {
  Checkbox,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Table,
  Title,
  Tooltip,
} from "@mantine/core";
import { useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();
  const updatePermission = useUpdateUserPermissionMutation();

  const companyPermissions = useGetCompanyPermissions({
    pathParams: { projectId: projectId || "-1" },
  });

  const userPermissions = useGetCompanyUsersPermissions({
    pathParams: { projectId: projectId || "-1" },
  });

  const isLoading = companyPermissions.isLoading || userPermissions.isLoading;

  const handlePermissionChange = (
    userId: string,
    permissionId: number,
    checked: boolean,
  ) => {
    if (!projectId) return;

    updatePermission.mutate({
      userId,
      projectId,
      permissionId,
      grant: checked,
    });
  };

  return (
    <Stack p="md" h="100%">
      <Title order={1}>Admin</Title>
      <CustomCard title="User Permissions" fill style={{ minHeight: 200 }}>
        <LoadingOverlay visible={isLoading} />
        <ScrollArea h="100%">
          {companyPermissions.data && userPermissions.data && (
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>User</Table.Th>
                  {companyPermissions.data?.map((permission) => (
                    <Table.Th key={permission.permission_id}>
                      <Tooltip label={permission.name_long}>
                        <span>{permission.name_short}</span>
                      </Tooltip>
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {userPermissions.data
                  ?.sort((a, b) => a.name_long.localeCompare(b.name_long))
                  .map((user) => (
                    <Table.Tr key={user.user_id}>
                      <Table.Td>{user.name_long}</Table.Td>
                      {companyPermissions.data?.map((permission) => (
                        <Table.Td key={permission.permission_id}>
                          <Checkbox
                            checked={user.permission_ids.includes(
                              permission.permission_id,
                            )}
                            onChange={(event) =>
                              handlePermissionChange(
                                user.user_id.toString(),
                                permission.permission_id,
                                event.currentTarget.checked,
                              )
                            }
                          />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          )}
        </ScrollArea>
      </CustomCard>
    </Stack>
  );
};

export default Page;
